import LocalizedStrings from "react-localization";

type Localization = {
  crash: string;
  contact_us: string;
  intercom: {
    cant_login_with_email: string;
  };
  login: {
    error: {
      misconfigured: string;
      expired_token: string;
      already_used_token: string;
      unknown: string;
    };
  };
  objective: {
    CONVERSION: string;
    CONSIDERATION: string;
    AWARENESS: string;
  };
  platform: {
    FACEBOOK: string;
    TIKTOK: string;
    GOOGLE_ADS: string;
  };
  status: {
    DRAFT: string;
    PENDING: string;
    ANALYZING: string;
    DONE: string;
    ERROR: string;
    CANCELLED: string;
  };
  learn: {
    label: string;
    name: string;
    description: string;
  };
  get_inspired: {
    label: string;
    name: string;
    description: string;
  };
  predict: {
    label: string;
    name: string;
    description: string;
    unfilled_scope_error: string;
    saving_error: string;
    file_type_error: string;
    file_size_error: { title: string; message: string };
    creative_score_comparison: {
      comparison: string;
      same: string;
      error: string;
      period_scope_info: string;
    };
    criterias: {
      title: string;
      show_more: string;
      show_less: string;
    };
    rank_by: {
      title: string;
      options: {
        general: string;
        unmatched: string;
        importance: string;
      };
    };
  };
  average_scores: {
    name: string;
    description: string;
  };
  my_learnings: {
    name: string;
    description: string;
  };
  industry_learnings: {
    name: string;
    description: string;
  };
  best_performers: {
    name: string;
    description: string;
  };
  hidden_gems: {
    name: string;
    description: string;
  };
  asset_type: {
    IMAGE: string;
    VIDEO: string;
  };
  learning_type: {
    IMAGE: string;
    VIDEO: string;
  };
  genders: {
    MALE: string;
    FEMALE: string;
    MIXED: string;
  };
  learning_detail: {
    confidence: string;
    scoring_importance: string;
    matching_with_your_assets: string;
    matching_with_industry_assets: string;
    about_feature: string;
    tags_info: {
      IFUSED: string;
      FIRST3S: string;
    };
  };
  cost_per_result: string;
  see_all: string;
  learn_more: string;
  campaign: {
    one: string;
    other: string;
  };
  ad: {
    one: string;
    other: string;
  };
  filters: {
    unable_to_load_filter: string;
    no_filter_with_matching_ressources: string;
  };
  dialog: {
    delete: {
      title: string;
      content: string;
      confirm: string;
      cancel: string;
    };
  };
};

const localization = new LocalizedStrings<Localization>({ "en-GB": require("/lang/en.json") });

export function formatPlural(key: string, count: number) {
  const safeCount = count || 0;
  const formattedAmount = formatAmount(safeCount);
  if (safeCount < 1) {
    const key_zero = localization.getString(`${key}.zero`);
    if (key_zero) return localization.formatString(key_zero, formattedAmount);
  }
  if (safeCount > 1) {
    const key_other = localization.getString(`${key}.other`);
    if (key_other) return localization.formatString(key_other, formattedAmount);
  }
  const key_one = localization.getString(`${key}.one`);
  if (key_one) return localization.formatString(key_one, formattedAmount);

  return localization.formatString(key, formattedAmount);
}

export function formatCurrency(amount: number, currencyCode: string, minimumFractionDigits = 2) {
  const safeAmount = amount || 0;
  const format = new Intl.NumberFormat(localization.getLanguage(), {
    style: "currency",
    currency: currencyCode,
    minimumFractionDigits,
  });

  let ret = format.format(safeAmount);
  if (safeAmount < 1) {
    const parts = format.formatToParts(safeAmount * 100.0);
    ret = parts
      .map((p) => {
        if (p.type === "currency") {
          return `ct${p.value}`;
        }
        return p.value;
      })
      .join("");
  } else if (safeAmount >= 10000) {
    const parts = format.formatToParts(safeAmount);
    ret = parts
      .map((p) => {
        if (p.type === "fraction" || p.type === "decimal") {
          return null;
        }
        return p.value;
      })
      .join("");
  }
  return ret;
}

export function formatAmount(amount: number) {
  return new Intl.NumberFormat(localization.getLanguage()).format(amount);
}

export function getOptionalString(path: string, language?: string, omitWarning?: boolean): string {
  const localizedString = localization.getString(path, language, omitWarning);
  return localizedString ? localizedString : path;
}

export default localization;
