import { ApolloProvider } from "@apollo/client";
import { AppProps } from "next/app";
import client from "../saas-gateway";

import { SnackbarProvider } from "@the-source/front-core/components";
import { ThemeProvider } from "@the-source/front-core/theme";
import { getSettings } from "@the-source/front-core/utils";
import Head from "next/head";
import ModalContainer from "react-modal-promise";
import { AuthProvider } from "../src/context/authContext";
import { FiltersProvider } from "../src/context/filtersContext";
import { SettingsProvider } from "../src/context/settingsContext";
import TrackingProvider from "../src/context/trackingContext";
import AuthGuard from "../src/guards/AuthGuard";
import Layout from "../src/layout";

import SuperTokensReact from "supertokens-auth-react";
import authConfig from "../config/authConfig";
import ErrorBoundary from "../src/components/ErrorBoundary";
import "./_app.css";

if (typeof window !== "undefined") {
  SuperTokensReact.init(authConfig());
}

function MyApp({ Component, pageProps }: AppProps): JSX.Element {
  return (
    <ThemeProvider themeMode={"light"}>
      <ErrorBoundary>
        <ApolloProvider client={client}>
          <TrackingProvider>
            <AuthProvider>
              <AuthGuard>
                <SettingsProvider>
                  <FiltersProvider>
                    <Head>
                      <link rel="shortcut icon" href="/assets/favicon.ico" />
                    </Head>
                    <Layout>
                      <ModalContainer />
                      <SnackbarProvider>
                        <Component {...pageProps} />
                      </SnackbarProvider>
                    </Layout>
                  </FiltersProvider>
                </SettingsProvider>
              </AuthGuard>
            </AuthProvider>
          </TrackingProvider>
        </ApolloProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
}

MyApp.getStaticProps = async () => {
  const settings = getSettings();

  return {
    props: { settings },
  };
};

export default MyApp;
