import type { FieldParameter } from "@the-source/front-core/components/filters";
import { FiltersFieldBooleanCriteriaList, FiltersFieldPeriod } from "@the-source/front-core/components/filters";
import { useRouter } from "next/router";
import { ParsedUrlQueryInput } from "querystring";
import React, { ReactNode, useState } from "react";
import { filtersOrder, negativeUrlPrefix } from "../preset";
import IsStringDateValid from "../utils/isStringDateValid";
import sortByCustomOrder from "../utils/sortByCustomOrder";

export type filteredPage = "learn" | "myLearnings" | "industryLearnings" | "bestPerformers" | "hiddenGems" | "inspired";

export type FilterConfig = {
  [key in filteredPage]?: FieldParameter<any, any>[];
};

type FilterProviderProps = {
  children: ReactNode;
};

export type ActiveFiltersType = {
  [key: string]: any[];
};

export type FilterValue = {
  field: string;
  value: any[];
};

type UrlParameters = {
  [page: string]: ParsedUrlQueryInput;
};

export type FiltersType = {
  resetFilters: (page: string) => void;
  hasRequiredValues: (page: filteredPage) => boolean;
  getActiveFilters: (page: string) => ActiveFiltersType;
  getFiltersConfig: (page: string) => FieldParameter<any, any>[];
  isInitialized: (page: string) => boolean;
  setFilter: (page: string, field: string, filters: any) => void;
  setFieldParameter: (page: string, fieldParameter: FieldParameter<any, any>) => void;
  setInitialUrlFilters: (page: string) => void;
  getFiltersUrlParameters: (page: string) => ParsedUrlQueryInput;
};

const FiltersContext = React.createContext<FiltersType | null>(null);
const { Provider } = FiltersContext;

const FiltersProvider = ({ children }: FilterProviderProps): JSX.Element => {
  const [filtersConfig, setFiltersConfig] = useState<FilterConfig>();
  const [activeFilters, setActiveFilters] = useState<ActiveFiltersType[]>([]);
  const [urlParameters, setUrlParameters] = useState<UrlParameters>({});
  const router = useRouter();

  function parseUrlValue(page: string, key: string, value: string): any {
    const filterConfig = getFiltersConfig(page).find((filter) => filter.name == key);
    let parsedValue: any;
    if (!filterConfig) parsedValue = undefined;
    else
      switch (filterConfig.component as any) {
        case FiltersFieldPeriod:
          parsedValue = new Date(JSON.parse(value));
          parsedValue = IsStringDateValid(parsedValue) ? parsedValue : undefined;
          break;
        case FiltersFieldBooleanCriteriaList:
          const positive = !value.startsWith(negativeUrlPrefix);
          const trimmedValue = positive ? value : value.slice(negativeUrlPrefix.length);
          parsedValue = filterConfig.choices.map((choice) => choice.value).includes(trimmedValue)
            ? { value: trimmedValue, positive }
            : undefined;
          break;
        default:
          parsedValue = value.replace(/"/g, "");
          parsedValue = filterConfig.choices.map((choice) => choice.value).includes(parsedValue)
            ? parsedValue
            : undefined;
          break;
      }
    return parsedValue;
  }

  function hasRequiredValues(page: filteredPage): boolean {
    return (
      !!filtersConfig &&
      page in filtersConfig &&
      !!activeFilters &&
      page in activeFilters &&
      (filtersConfig[page] as FieldParameter<any, any>[]).every(
        (fieldParameter) =>
          !fieldParameter.required ||
          (fieldParameter.name in activeFilters[page] && activeFilters[page][fieldParameter.name].length > 0)
      )
    );
  }

  function getActiveFilters(page: string): ActiveFiltersType {
    const filters = {};
    const initialFilters = activeFilters[page] ? activeFilters[page] : getDefaultFilters(page);
    const urlFilters = getUrlFilters(page);

    for (const [key, values] of Object.entries(initialFilters)) {
      filters[key] = urlFilters[key] ? urlFilters[key] : values;
    }
    return filters;
  }

  function getDefaultFilters(page: string): ActiveFiltersType {
    const defaultFilters = {};
    getFiltersConfig(page).forEach((filter: FieldParameter<any, any>) => {
      defaultFilters[filter.name] = filter.defaultValues;
    });
    return defaultFilters;
  }

  function getUrlFilters(page: string): any {
    const queryParams = router.query;
    const tmpFilters = {};
    for (const [key, values] of Object.entries(queryParams)) {
      if (values == "none") {
        tmpFilters[key] = [];
      } else {
        const parsedValues = (Array.isArray(values) ? values : [values]).map((value: string) =>
          parseUrlValue(page, key, value)
        );
        if (!parsedValues.every((parsedValue) => parsedValue == undefined))
          tmpFilters[key] = parsedValues.filter((parsedValue) => parsedValue != undefined);
      }
    }
    return tmpFilters;
  }

  function setInitialUrlFilters(page: string) {
    if (urlParameters[page])
      router.replace({
        pathname: router.pathname,
        query: urlParameters[page],
      });
    else setUrlParameters((urlParameters) => ({ ...urlParameters, [page]: router.query }));
  }

  function setUrlFilter(page: string, field: string, values: any[]) {
    const fieldParameter = filtersConfig?.[page].find(
      (filterConfig: FieldParameter<any, any>) => filterConfig.name == field
    );
    let newUrlParameters = {};
    switch (fieldParameter.component) {
      case FiltersFieldBooleanCriteriaList:
        newUrlParameters = {
          ...(urlParameters[page] ?? router.query),
          [field]: values.map(({ value, positive }) => (positive ? value : `${negativeUrlPrefix}${value}`)),
        };
        break;
      default:
        newUrlParameters = {
          ...(urlParameters[page] ?? router.query),
          [field]:
            values.length == 0 && fieldParameter.defaultValues.length > 0
              ? "none"
              : values.map((value) => JSON.stringify(value)),
        };
        break;
    }
    setUrlParameters((urlParameters) => ({
      ...urlParameters,
      [page]: newUrlParameters,
    }));
    router.replace({
      pathname: router.pathname,
      query: newUrlParameters,
    });
  }

  function getFiltersUrlParameters(page: string) {
    return urlParameters[page];
  }

  function setFilter(page: string, field: string, filters: ActiveFiltersType) {
    setUrlFilter(page, field, filters[field]);
    setActiveFilters((previousActiveFilters) => ({ ...previousActiveFilters, [page]: { ...filters } }));
  }

  function createOrUpdateFieldParameter(page: filteredPage, fieldParameter: FieldParameter<any, any>) {
    setFiltersConfig((previous) => {
      const pageFiltersConfig = (!!previous?.[page] ? previous[page] : []) as FieldParameter<any, any>[];
      const fieldParameterIndex = pageFiltersConfig.findIndex(({ name }) => fieldParameter.name == name);
      if (fieldParameterIndex > -1) pageFiltersConfig[fieldParameterIndex] = fieldParameter;
      else pageFiltersConfig.push(fieldParameter);
      const sortedPageFiltersConfig = sortByCustomOrder(pageFiltersConfig, filtersOrder, ({ name }) => name);
      return { ...previous, [page]: sortedPageFiltersConfig };
    });
  }

  function initializeOrCleanValues(page: filteredPage, fieldParameter: FieldParameter<any, any>) {
    setActiveFilters((previous) => {
      let fieldValues = previous?.[page]?.[fieldParameter.name] ?? [];
      const fieldChoiceValues = fieldParameter.choices.map(({ value }) => value);
      const fieldValuesMatchingFieldChoices = (fieldValues as any[]).filter((fieldValue) =>
        fieldChoiceValues.find((choiceValue) => choiceValue == fieldValue || choiceValue == fieldValue.value)
      );
      fieldValues = fieldValuesMatchingFieldChoices;
      if (fieldValues.length == 0) fieldValues = fieldParameter.defaultValues;
      return {
        ...previous,
        [page]: {
          ...(previous?.[page] ? previous[page] : {}),
          [fieldParameter.name]: fieldValues,
        },
      };
    });
  }

  function setFieldParameter(page: filteredPage, fieldParameter: FieldParameter<any, any>) {
    createOrUpdateFieldParameter(page, fieldParameter);
    initializeOrCleanValues(page, fieldParameter);
  }

  function getFiltersConfig(page: string): FieldParameter<any, any>[] {
    const filters = filtersConfig?.[page] ?? [];
    return [...filters];
  }

  function isInitialized(page: string) {
    return !!filtersConfig && page in filtersConfig;
  }

  function resetFilters(page: string) {
    setActiveFilters((previousActiveFilters) => {
      const newActiveFilters = { ...previousActiveFilters };
      const previousPageActiveFilters = previousActiveFilters[page];
      const defaultFilters = getDefaultFilters(page);
      for (const [key] of Object.entries(previousPageActiveFilters)) {
        newActiveFilters[page][key] = defaultFilters[key];
      }
      return newActiveFilters;
    });
    setUrlParameters((urlParameters) => ({ ...urlParameters, [page]: {} }));
    router.replace({
      pathname: router.pathname,
      query: undefined,
    });
  }

  return (
    <Provider
      value={{
        setFilter,
        setFieldParameter,
        resetFilters,
        hasRequiredValues,
        getActiveFilters,
        getFiltersConfig,
        isInitialized,
        setInitialUrlFilters,
        getFiltersUrlParameters,
      }}
    >
      {children}
    </Provider>
  );
};

export { FiltersContext, FiltersProvider };
