import { SvgIconStyle } from "@the-source/front-core/components";
import { PropsWithChildren } from "react";
import localization from "./localization";
import NextLink from "next/link";

const navigationItems = [
  { title: localization.get_inspired.label, path: "/", icon: "ic_components" },
  {
    title: localization.learn.label,
    path: "/learn",
    icon: "ic_receipt",
    subItems: [
      {
        title: localization.my_learnings.name,
        path: "/learn/my-learnings",
      },
      {
        title: localization.industry_learnings.name,
        path: "/learn/industry-learnings",
      },
      {
        title: localization.best_performers.name,
        path: "/learn/best-performers",
      },
      {
        title: localization.hidden_gems.name,
        path: "/learn/hidden-gems",
      },
    ],
  },
  { title: localization.predict.label, path: "/asset-scoring", icon: "ic_analytics" },
].map((item) =>
  Object.assign(item, {
    icon: <SvgIconStyle src={`/assets/icons/${item.icon}.svg`} sx={{ width: 1, height: 1 }} />,
    container: ({ children }: PropsWithChildren<any>) => (
      <NextLink href={item.path} passHref legacyBehavior>
        {children}
      </NextLink>
    ),
    subItems: item.subItems?.map((subItem) => ({
      ...subItem,
      ...{
        container: ({ children }: PropsWithChildren<any>) => (
          <NextLink href={subItem.path} passHref legacyBehavior>
            {children}
          </NextLink>
        ),
      },
    })),
  })
);

export default navigationItems;
