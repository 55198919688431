import React, { useContext, useEffect, useMemo } from "react";
import * as FullStory from "@fullstory/browser";

type IdentifyUser = {
  id: string;
  name: string;
  email: string;
};
export type TrackingContextProps = {
  identify: (user: IdentifyUser) => void;
};
export const TrackingContext = React.createContext<TrackingContextProps>({
  identify: () => {
    throw new Error("TrackingContext not initialized yet");
  },
});

const { Provider } = TrackingContext;

type TrackingProviderProps = {
  children: JSX.Element;
};

const TrackingProvider = ({ children }: TrackingProviderProps): JSX.Element => {
  const devMode = useMemo(() => process.env.NEXT_PUBLIC_STAGE === undefined, [process.env.NEXT_PUBLIC_STAGE]);

  useEffect(() => {
    if (devMode) return;
    FullStory.init({
      orgId: process.env.NEXT_PUBLIC_FULLSTORY_APP_ID as string,
    });
  }, []);

  function identify({ id, name, email }: IdentifyUser): void {
    if (devMode) return;
    FullStory.identify(id, { name, email });
    if ((window as any).heap) {
      (window as any).heap.identify(id);
      (window as any).heap.addUserProperties({ name, email });
    }
  }

  return <Provider value={{ identify }}>{children}</Provider>;
};

export const useTrackingContext = (): TrackingContextProps => useContext(TrackingContext);

export default TrackingProvider;
