/***  THEME  ***/
import { enUS, frFR } from "@mui/material/locale";
import { Gender, Status } from "./graphql/graphql";

export const filtersOrder = [
  "industry",
  "brand",
  "objective",
  "platform",
  "period",
  "format",
  "theme",
  "brandFeature",
  "industryFeature",
  "brandKpi",
  "industryKpi",
  "ageRange",
  "gender",
  "brandConfidence",
  "industryConfidence",
  "featurePresence",
];

export const thresholds = [
  { threshold: 0, color: "error" },
  { threshold: 15, color: "warning" },
  { threshold: 45, color: "primary" },
];

const green = {
  strong: "#187A5C",
  moderate: "#39C88C",
  slight: "#99F8CD",
  faint: "#CAF2E2",
};

export const interpreterConfidenceDotOpacities = [
  {
    confidence: "strong",
    color: green.strong,
  },
  {
    confidence: "moderate",
    color: green.moderate,
  },
  {
    confidence: "slight",
    color: green.slight,
  },
  {
    confidence: "faint",
    color: green.faint,
  },
];

export const allLangs = [
  {
    label: "English",
    value: "en",
    systemValue: enUS,
    icon: "/assets/icons/flags/ic_flag_en.svg",
  },
  {
    label: "French",
    value: "fr",
    systemValue: frFR,
    icon: "/assets/icons/flags/ic_flag_fr.svg",
  },
];
export const learningsPerContainerOnLearn = 3;
export const learningsPerMasonryPage = 10;
export const learningPagesPerMasonryBatch = 1;
export const assetsPerContainerOnLearn = 4;
export const outstandingAssetsPerMasonryPage = 10;
export const outstandingAssetPagesPerMasonryBatch = 5;
export const analysisAverageCreativeScoresMonthsPerdiod = 12;

export const interpreterMaxCharacters = 50;
export const newLabelDaysThreshold = 15;
export const interpreterVariablePrefix = "detailed_version__";
export const interpreterKpiVariable = "kpi";
export const interpreterUnitVariable = "unit";
export const interpreterTimeVariables = ["video_time", "interval_value"];
export const interpreterVariablesToHighlight = [
  interpreterKpiVariable,
  ...interpreterTimeVariables,
  "element",
  "value",
  "interval_value",
  "video_time",
];

export const contactEmail = {
  adress: "hello@creativelift.ai",
  subject:
    "%F0%9F%A4%94%20I%20want%20to%20know%20more%20about%20your%20product...&body=Hi%2C%0d%0a%0d%0aI%20want%20to%20more%20about%20Creative%20Lift!%0d%0aWhat%20do%20you%20guys%20do%20exactly%3F%20I%20understood%20this%20is%20about%20AI-generated%20creative%20insights...%3F%0d%0a%0d%0aHave%20a%20good%20one!%0d%0a",
};

export const defaultLang = allLangs[0];

export const objectiveFamiliesOrder = ["AWARENESS", "CONSIDERATION", "CONVERSION"];
export const analysisStatusOrder = [
  Status.Draft,
  Status.Pending,
  Status.Analyzing,
  Status.Cancelled,
  Status.Error,
  Status.Done,
];
export const gendersOrder = [Gender.Male, Gender.Female, Gender.Mixed];

export const cloudStoragePrefixHTTP = "https://storage.googleapis.com/";
export const cloudStorageSignedURLEndpoint = "https://api.creativelift.ai/cloud-storage/api/v1/signed-url";

export const negativeUrlPrefix = "no-";

export const analysisTableDefaultRowsPerPage = 20;

export const getInspiredAssetsPerMasonryPage = 10;
export const getInspiredPagePerMasonryBatch = 10;
