import { Button, Stack, Typography } from "@mui/material";
import { SeverErrorIllustration } from "@the-source/front-core/assets";
import { ReactSVG } from "react-svg";
import { Router, withRouter } from "next/router";
import { Component } from "react";
import localization from "../localization";
import deleteAuthCookies from "../utils/deleteAuthCookies";

type ErrorBoundaryProps = {
  children?: JSX.Element;
  router: Router;
};
class ErrorBoundary extends Component<ErrorBoundaryProps, { hasError: boolean }> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
    this.handleClickOnBack = this.handleClickOnBack.bind(this);
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  handleClickOnBack() {
    deleteAuthCookies();
    this.props.router.push("/");
    location.reload();
  }

  render() {
    if (this.state.hasError) {
      return (
        <Stack gap={5} alignItems="center" alignContent="center" maxWidth="33%" marginX="auto" marginTop={10}>
          <ReactSVG src={SeverErrorIllustration} />
          <Typography variant="h4">{localization.crash}</Typography>
          <Button variant="contained" size="large" onClick={this.handleClickOnBack}>
            Restart
          </Button>
        </Stack>
      );
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
