import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { UserPartsFragment, useUserLazyQuery } from "../graphql/graphql";
import { useTrackingContext } from "./trackingContext";

import { useRouter } from "next/router";
import { doesSessionExist, signOut } from "supertokens-auth-react/recipe/session";
import {
  consumePasswordlessCode,
  createPasswordlessCode,
  getPasswordlessLinkCodeFromURL,
} from "supertokens-auth-react/recipe/thirdpartypasswordless";
import deleteAuthCookies from "../utils/deleteAuthCookies";

export type Brand = {
  id: string;
  name: string;
  industries: Industry[];
};

export type Industry = {
  id: string;
  name: string;
  singledBranded: boolean;
};

export type AuthContextType = {
  hasUser: () => boolean;
  getUser: () => UserPartsFragment;
  getUserIndustries: () => Industry[];
  sendMagiclink: (email: string) => void;
  logout: () => void;
  isLoading: boolean;
  isMagiclinkSent: boolean;
  error?: Error;
};

type AuthProviderProps = {
  children: ReactNode;
};

type Error = "already_used_token" | "expired_token" | "misconfigured" | "unknown";

const AuthContext = React.createContext<AuthContextType | undefined>(undefined);
const { Provider } = AuthContext;

const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
  const [fetchUser] = useUserLazyQuery();
  const [user, setUser] = useState<UserPartsFragment>();
  const [error, setError] = useState<Error>();
  const [isLoading, setIsLoading] = useState(true);
  const [isMagiclinkSent, setIsMagiclinkSent] = useState(false);

  const trackingContext = useTrackingContext();

  const router = useRouter();

  function fail(error: Error) {
    setError(error);
    setIsLoading(false);
  }

  function sendMagiclink(email: string) {
    createPasswordlessCode({ email })
      .then(({ status }) => {
        if (status == "OK") setIsMagiclinkSent(true);
        else setError("unknown");
      })
      .catch((_) => setError("unknown"));
  }

  async function handleMagiclink() {
    consumePasswordlessCode()
      .then(({ status: codeStatus }) => {
        switch (codeStatus) {
          case "OK":
            loadUser();
            break;
          case "EXPIRED_USER_INPUT_CODE_ERROR":
            fail("expired_token");
            break;
          case "RESTART_FLOW_ERROR":
            fail("already_used_token");
            break;
          default:
            fail("unknown");
            break;
        }
      })
      .catch((_) => fail("unknown"))
      .finally(() => router.replace("/", undefined));
  }

  function loadUser() {
    fetchUser()
      .then(({ data }) => {
        if (!data) {
          fail("unknown");
          deleteAuthCookies();
        } else if (data.user.brands.length == 0) {
          fail("misconfigured");
          deleteAuthCookies();
        } else {
          const { user } = data;
          setUser(user);
          trackingContext.identify(user);
          setIsLoading(false);
        }
      })
      .catch((_) => fail("unknown"));
  }

  const logout = (): void => {
    signOut().then(() => {
      setUser(undefined);
      deleteAuthCookies();
    });
  };

  useEffect(() => {
    doesSessionExist().then((exist) => {
      if (exist) loadUser();
      else if (getPasswordlessLinkCodeFromURL().length > 0) {
        handleMagiclink();
      } else setIsLoading(false);
    });
  }, []);

  function hasUser() {
    return !!user;
  }

  function getUser(): UserPartsFragment {
    if (!user) throw new Error("User has not been authenticated yet");
    return user as UserPartsFragment;
  }

  const getUserIndustries = useCallback((): Industry[] => {
    const userIndustries: Industry[] = [];
    getUser().brands.forEach((brand) =>
      brand.industries.forEach((brandIndustry) => {
        if (!userIndustries.map((industry) => industry.id).includes(brandIndustry.id))
          userIndustries.push({ ...brandIndustry, singledBranded: brandIndustry.brands.length == 1 });
      })
    );
    return userIndustries;
  }, [user]);

  return (
    <Provider
      value={{
        hasUser,
        getUser,
        getUserIndustries,
        sendMagiclink,
        logout,
        isLoading,
        isMagiclinkSent,
        error,
      }}
    >
      {children}
    </Provider>
  );
};

export { AuthContext, AuthProvider };
