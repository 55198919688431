import { Avatar, ListItemIcon, ListItemText, Menu, MenuItem, Typography, useTheme } from "@mui/material";
import { Iconify, OverflowTypography, UserButton } from "@the-source/front-core/components";
import { SyntheticEvent, useMemo, useState } from "react";
import cropEmailUsername from "../../utils/cropEmailAdress";
import getRandomColor from "../../utils/getRandomColor";
import getStringInitials from "../../utils/getStringInitials";

type UserMenuProps = {
  username: string;
  email: string;
  onSignOut: () => void;
};
const UserMenu = ({ username, email: fullEmail, onSignOut }: UserMenuProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const theme = useTheme();

  const email = useMemo(() => cropEmailUsername(fullEmail, 20), [fullEmail]);
  const isEmailCropped = useMemo(() => email !== fullEmail, [email, fullEmail]);

  const handleClick = (event: SyntheticEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = (): void => {
    setAnchorEl(null);
  };

  const handleSignOut = (): void => {
    onSignOut();
    closeMenu();
  };

  const InitialsAvatar = (): JSX.Element => {
    return <Avatar sx={{ bgcolor: getRandomColor(username) }}>{getStringInitials(username)}</Avatar>;
  };

  return (
    <>
      <UserButton
        sx={{ mt: "auto", overflow: "hidden", width: "100%" }}
        title={username}
        avatar={<InitialsAvatar />}
        subTitle={
          <OverflowTypography noWrap textOverflow="ellipsis" {...(isEmailCropped && { forceTooltip: fullEmail })}>
            {email}
          </OverflowTypography>
        }
        onClick={handleClick}
      />
      <Menu
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        transformOrigin={{ vertical: "bottom", horizontal: "center" }}
        MenuListProps={{ sx: { width: anchorEl ? anchorEl.offsetWidth : 0, padding: 0 } }}
        sx={{ transform: `translate(0, -${theme.spacing(1)})` }}
      >
        <MenuItem onClick={handleSignOut} sx={{ padding: theme.spacing(2, 2.5) }}>
          <ListItemIcon sx={{ marginX: "auto" }}>
            <Iconify color="red" icon={"ic:outline-log-out"} width="40px" fontSize={theme.typography.fontSize * 1.5} />
          </ListItemIcon>
          <ListItemText sx={{ marginX: theme.spacing(2) }}>
            <Typography color="red">Sign out</Typography>
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserMenu;
