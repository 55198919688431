import { createContext, ReactNode, useContext, useState } from "react";
import { analysisTableDefaultRowsPerPage } from "../preset";

const initialState = {
  predictRowsPerPage: analysisTableDefaultRowsPerPage,
  setPredictRowsPerPage: () => {},
};

type SettingsContextProps = {
  predictRowsPerPage: number;
  setPredictRowsPerPage: (rowPerPage: number) => void;
};

const Settings = createContext<SettingsContextProps>(initialState);

type SettingsProviderProps = {
  children: ReactNode;
};

export const SettingsProvider = ({ children }: SettingsProviderProps) => {
  const [predictRowsPerPage, setPredictRowsPerPage] = useState(analysisTableDefaultRowsPerPage);

  return (
    <Settings.Provider
      value={{
        predictRowsPerPage,
        setPredictRowsPerPage,
      }}
    >
      {children}
    </Settings.Provider>
  );
};

export const useSettingsContext = () => useContext(Settings);
