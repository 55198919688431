export default function sortByCustomOrder<I, P>(
  items: I[],
  order: P[],
  selectProperty: (item: I) => P,
  reverse?: boolean
) {
  return reverse
    ? items.sort((a, b) => order.indexOf(selectProperty(b)) - order.indexOf(selectProperty(a)))
    : items.sort((a, b) => order.indexOf(selectProperty(a)) - order.indexOf(selectProperty(b)));
}
