import { Box } from "@mui/material";
import { NavigationMenu } from "@the-source/front-core/components";
import { useRouter } from "next/router";
import { ReactNode, useContext } from "react";
import UserMenu from "./components/navigation/UserMenu";
import { AuthContext, AuthContextType } from "./context/authContext";
import navigationItems from "./navigation";

type Props = {
  children: ReactNode;
};

const Layout = ({ children }: Props): JSX.Element => {
  const { pathname } = useRouter();
  const auth = useContext(AuthContext) as AuthContextType;

  return (
    <Box
      sx={{
        display: "flex",
        minHeight: 1,
        backgroundColor: "rgba(30, 32, 35, 0.05)",
      }}
    >
      <NavigationMenu currentPath={pathname} items={navigationItems}>
        <UserMenu username={auth.getUser().name} email={auth.getUser().email} onSignOut={() => auth.logout()} />
      </NavigationMenu>
      <Box
        component="main"
        sx={{
          width: "100%",
          overflow: "clip",
          minHeight: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default Layout;
