import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";
import { RetryLink } from "@apollo/client/link/retry";
import { getAccessTokenPayloadSecurely } from "supertokens-auth-react/recipe/session";
import analysisGroupsFieldPolicy from "./src/cacheFieldPolicies/analysisGroups";
import learningsFieldPolicy from "./src/cacheFieldPolicies/learnings";

const retryLink = new RetryLink({
  attempts: {
    retryIf: (error) => {
      return error.message == "Failed to fetch";
    },
  },
});

const httpLink = createUploadLink({ uri: process.env.NEXT_PUBLIC_SAAS_GATEWAY });

const authLink = setContext(async (_, { headers }) => {
  const token = await getAccessTokenPayloadSecurely();
  return {
    headers: {
      ...headers,
      authorization: token.jwt ? `Bearer ${token.jwt}` : "",
    },
  };
});

const client = new ApolloClient({
  link: ApolloLink.from([retryLink, authLink, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          ...analysisGroupsFieldPolicy,
          ...learningsFieldPolicy,
        },
      },
      CreativeScoreLearning: {
        keyFields: ["liftId", "assetId", "industryId", "objectiveFamilyId", "platform"],
      },
      AnalysisRequest: {
        fields: {
          isUploading: {
            read(existing) {
              return existing || false;
            },
          },
        },
      },
    },
  }),
  defaultOptions: {
    watchQuery: {
      notifyOnNetworkStatusChange: true,
    },
  },
});

export default client;
