import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date with time (isoformat) */
  DateTime: any;
  UUID: any;
  Upload: any;
};

export type AgeRange = {
  __typename: 'AgeRange';
  ageRange: Scalars['String'];
  name: Scalars['String'];
};

export type AnalysisCreateInput = {
  brandId?: InputMaybe<Scalars['String']>;
  groupId: Scalars['String'];
  industryId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  objectiveFamilyId?: InputMaybe<Scalars['String']>;
  platform?: InputMaybe<Platform>;
  upload: Scalars['Upload'];
};

export type AnalysisGroup = {
  __typename: 'AnalysisGroup';
  analysisRequests: Array<AnalysisRequest>;
  brandIds: Array<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Boolean'];
  id: Scalars['UUID'];
  name: Scalars['String'];
  numAssets: Scalars['Int'];
  status: Status;
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
};

export type AnalysisGroupNotFoundError = AnalysisGroup | NotFoundError;

export type AnalysisGroupNotReadyError = AnalysisGroup | NotReadyError;

export type AnalysisMutation = {
  __typename: 'AnalysisMutation';
  createAnalysisGroup: AnalysisGroup;
  createAnalysisRequest: Array<AnalysisRequestAssetTypeErrorAssetSizeErrorBrandsNotAllowedErrorObjectiveFamiliesNotFoundError>;
  deleteAnalysisGroup: SuccessNotFoundErrorNotPermittedError;
  deleteAnalysisRequest: SuccessNotFoundError;
  startAnalysisGroup: AnalysisGroupNotReadyError;
  updateAnalysisGroup: AnalysisGroupNotFoundError;
  updateAnalysisRequest: NotFoundErrorAnalysisRequestAssetTypeErrorAssetSizeErrorBrandsNotAllowedErrorObjectiveFamiliesNotFoundError;
};


export type AnalysisMutationCreateAnalysisGroupArgs = {
  name: Scalars['String'];
};


export type AnalysisMutationCreateAnalysisRequestArgs = {
  createInputs: Array<AnalysisCreateInput>;
};


export type AnalysisMutationDeleteAnalysisGroupArgs = {
  analysisGroupId: Scalars['String'];
};


export type AnalysisMutationDeleteAnalysisRequestArgs = {
  analysisRequestId: Scalars['String'];
};


export type AnalysisMutationStartAnalysisGroupArgs = {
  analysisGroupId: Scalars['String'];
};


export type AnalysisMutationUpdateAnalysisGroupArgs = {
  analysisGroupId: Scalars['String'];
  name: Scalars['String'];
};


export type AnalysisMutationUpdateAnalysisRequestArgs = {
  updateInput: AnalysisUpdateInput;
};

export type AnalysisRequest = {
  __typename: 'AnalysisRequest';
  analysisGroupId: Scalars['String'];
  assets?: Maybe<Array<PredictAsset>>;
  brand?: Maybe<Brand>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Boolean'];
  file: Scalars['String'];
  /** size in bytes */
  fileSize: Scalars['Int'];
  fileType: AssetType;
  fileUrl: Scalars['String'];
  groupId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  industry?: Maybe<Industry>;
  isUploading: Scalars['Boolean'];
  name: Scalars['String'];
  objectiveFamily?: Maybe<ObjectiveFamily>;
  platform?: Maybe<Platform>;
  status: Status;
  thumbnailUrl?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type AnalysisRequestAssetTypeErrorAssetSizeErrorBrandsNotAllowedErrorObjectiveFamiliesNotFoundError = AnalysisRequest | AssetSizeError | AssetTypeError | BrandsNotAllowedError | ObjectiveFamiliesNotFoundError;

export type AnalysisUpdateInput = {
  analysisRequestId: Scalars['String'];
  brandId?: InputMaybe<Scalars['String']>;
  industryId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  objectiveFamilyId?: InputMaybe<Scalars['String']>;
  platform?: InputMaybe<Platform>;
  upload?: InputMaybe<Scalars['Upload']>;
};

export type Asset = {
  __typename: 'Asset';
  adEndedAt: Scalars['DateTime'];
  adStartedAt: Scalars['DateTime'];
  ageRanges: Array<Scalars['String']>;
  analysisDate: Scalars['DateTime'];
  assetType: AssetType;
  brand: Brand;
  creativeScore?: Maybe<Scalars['Float']>;
  fileUrl: Scalars['String'];
  genders: Array<Gender>;
  id: Scalars['String'];
  industryId: Scalars['String'];
  industryName: Scalars['String'];
  learnings: Array<Learning>;
  objectiveFamily: ObjectiveFamily;
  platform: Platform;
  presentFeatures: Array<Scalars['String']>;
  spend: Scalars['Float'];
  themeIds: Array<Scalars['String']>;
  themes: Array<Theme>;
  thumbnailUrl: Scalars['String'];
};

export type AssetFeatureCategory = {
  __typename: 'AssetFeatureCategory';
  categoryFrontDescription: Scalars['String'];
  categoryFrontName: Scalars['String'];
};

export type AssetQueryParameters = {
  after?: InputMaybe<Scalars['DateTime']>;
  ageRanges?: InputMaybe<Array<Scalars['String']>>;
  before?: InputMaybe<Scalars['DateTime']>;
  /** May be ignored in the context of a brand */
  brandIds?: InputMaybe<Array<Scalars['String']>>;
  format?: InputMaybe<AssetType>;
  genders?: InputMaybe<Array<Gender>>;
  /** May be ignored in the context of an industry */
  industryIds?: InputMaybe<Array<Scalars['String']>>;
  objectiveFamilyIds?: InputMaybe<Array<Scalars['String']>>;
  platforms?: InputMaybe<Array<Platform>>;
  themeIds?: InputMaybe<Array<Scalars['String']>>;
};

export type AssetSizeError = {
  __typename: 'AssetSizeError';
  fileName: Scalars['String'];
  maximumFileSize: Scalars['String'];
  message?: Maybe<Scalars['String']>;
};

export enum AssetType {
  Image = 'IMAGE',
  Video = 'VIDEO'
}

export type AssetTypeError = {
  __typename: 'AssetTypeError';
  message?: Maybe<Scalars['String']>;
};

export type BestPerformer = {
  __typename: 'BestPerformer';
  asset: Asset;
  cpa: Scalars['Float'];
  id: Scalars['String'];
  nbAds: Scalars['Int'];
  nbCampaigns: Scalars['Int'];
  objectiveFamilyId: Scalars['String'];
  roundedCpa: Scalars['Float'];
  totalResult: Scalars['Float'];
  totalSpend: Scalars['Float'];
};

export type Brand = {
  __typename: 'Brand';
  assetsCount: Scalars['Int'];
  averageCreativeScore?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  industries: Array<Industry>;
  logos: Array<Logo>;
  name: Scalars['String'];
  primaryVerticals: Array<Industry>;
  slug: Scalars['String'];
};


export type BrandAssetsCountArgs = {
  after?: InputMaybe<Scalars['DateTime']>;
  before?: InputMaybe<Scalars['DateTime']>;
  format?: InputMaybe<AssetType>;
  industryIds?: InputMaybe<Array<Scalars['String']>>;
  objectiveFamilyIds?: InputMaybe<Array<Scalars['String']>>;
  platforms?: InputMaybe<Array<Platform>>;
};


export type BrandAverageCreativeScoreArgs = {
  parameters?: InputMaybe<AssetQueryParameters>;
};

export type BrandNotFoundError = Brand | NotFoundError;

export type BrandScopedLearningFilters = {
  after?: InputMaybe<Scalars['DateTime']>;
  assetTypes?: InputMaybe<Array<AssetType>>;
  before?: InputMaybe<Scalars['DateTime']>;
  brandIds: Array<Scalars['String']>;
  confidences?: InputMaybe<Array<Scalars['String']>>;
  featureCategories?: InputMaybe<Array<Scalars['String']>>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  industryIds?: InputMaybe<Array<Scalars['String']>>;
  kpis?: InputMaybe<Array<Scalars['String']>>;
  objectiveFamilyIds?: InputMaybe<Array<Scalars['String']>>;
  platforms?: InputMaybe<Array<Platform>>;
  themeIds?: InputMaybe<Array<Scalars['String']>>;
};

export type BrandsNotAllowedError = {
  __typename: 'BrandsNotAllowedError';
  message?: Maybe<Scalars['String']>;
};

export type Confidence = {
  __typename: 'Confidence';
  name: Scalars['String'];
};

export type CreativeScoreLearning = {
  __typename: 'CreativeScoreLearning';
  aggregationKey?: Maybe<Scalars['String']>;
  assetId: Scalars['String'];
  industryId: Scalars['String'];
  isMatchingLift: Scalars['Boolean'];
  learning: Learning;
  liftId: Scalars['String'];
  objectiveFamilyId: Scalars['String'];
  platform: Platform;
  weight: Scalars['Float'];
};

export type CreativeScoreLearningsFilters = {
  assetIds: Array<Scalars['String']>;
  industryIds: Array<Scalars['String']>;
  objectiveFamilyIds: Array<Scalars['String']>;
  platforms: Array<Platform>;
};

export enum ErrorCodes {
  AnalysisUnknownStatus = 'ANALYSIS_UNKNOWN_STATUS',
  AnalysisUnmanagedAssetType = 'ANALYSIS_UNMANAGED_ASSET_TYPE',
  ApiInstanceNotFound = 'API_INSTANCE_NOT_FOUND',
  ApiUnauthorized = 'API_UNAUTHORIZED',
  Internal = 'INTERNAL',
  RequestParseError = 'REQUEST_PARSE_ERROR',
  UsageOrderLengthMismatch = 'USAGE_ORDER_LENGTH_MISMATCH',
  UsageOrderUnknownField = 'USAGE_ORDER_UNKNOWN_FIELD'
}

export type FeatureCategory = {
  __typename: 'FeatureCategory';
  categoryFrontDescription: Scalars['String'];
  categoryFrontName: Scalars['String'];
};

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE',
  Mixed = 'MIXED'
}

export type HiddenGem = {
  __typename: 'HiddenGem';
  asset: Asset;
  cpa: Scalars['Float'];
  id: Scalars['String'];
  nbAds: Scalars['Int'];
  nbCampaigns: Scalars['Int'];
  objectiveFamilyId: Scalars['String'];
  roundedCpa: Scalars['Float'];
  totalResult: Scalars['Float'];
  totalSpend: Scalars['Float'];
};

export type Industry = {
  __typename: 'Industry';
  averageCreativeScore?: Maybe<Scalars['Float']>;
  brands: Array<Brand>;
  id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
};


export type IndustryAverageCreativeScoreArgs = {
  parameters?: InputMaybe<AssetQueryParameters>;
};

export type IndustryScopedLearningFilters = {
  after?: InputMaybe<Scalars['DateTime']>;
  assetTypes?: InputMaybe<Array<AssetType>>;
  before?: InputMaybe<Scalars['DateTime']>;
  confidences?: InputMaybe<Array<Scalars['String']>>;
  featureCategories?: InputMaybe<Array<Scalars['String']>>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  industryIds: Array<Scalars['String']>;
  kpis?: InputMaybe<Array<Scalars['String']>>;
  objectiveFamilyIds?: InputMaybe<Array<Scalars['String']>>;
  platforms?: InputMaybe<Array<Platform>>;
  themeIds?: InputMaybe<Array<Scalars['String']>>;
};

export type Interpreter = {
  __typename: 'Interpreter';
  detailedVersion: InterpreterPhrase;
  impact: InterpreterPhrase;
  shortVersion: InterpreterPhrase;
  tags: Array<Scalars['String']>;
  title: Scalars['String'];
};

export type InterpreterPhrase = {
  __typename: 'InterpreterPhrase';
  filledTemplate: Scalars['String'];
  interpolatedKeys: Array<Scalars['String']>;
  interpolatedValues: Array<Scalars['String']>;
  template: Scalars['String'];
};

export type Kpi = {
  __typename: 'KPI';
  kpi: Scalars['String'];
  name: Scalars['String'];
};

export type Learning = {
  __typename: 'Learning';
  assetType: AssetType;
  assets: Array<Asset>;
  brand?: Maybe<Brand>;
  brandMeasure: Scalars['Float'];
  campaignObjective: Scalars['String'];
  confidence: Scalars['String'];
  createdAt: Scalars['DateTime'];
  feature: Scalars['String'];
  featureCategory?: Maybe<FeatureCategory>;
  /** Rank of this lift's feature-kpi tuple among all feature-kpi tuples */
  featureKpiRank: Scalars['Int'];
  /** Rank of this lift among lifts with the same feature-kpi tuple */
  groupRank: Scalars['Int'];
  id: Scalars['String'];
  industry: Industry;
  industryMeasure: Scalars['Float'];
  interpreterV2: Interpreter;
  kpi: Scalars['String'];
  kpiName: Scalars['String'];
  liftPercent: Scalars['Float'];
  objectiveFamily: ObjectiveFamily;
  optimizationGoal: Scalars['String'];
  platform: Platform;
  scope: ValuesScope;
  scopeEndedAt: Scalars['DateTime'];
  scopeStartedAt: Scalars['DateTime'];
  themes: Array<Theme>;
};

export type Logo = {
  __typename: 'Logo';
  id: Scalars['String'];
  url: Scalars['String'];
};

export type NotFoundError = {
  __typename: 'NotFoundError';
  message?: Maybe<Scalars['String']>;
};

export type NotFoundErrorAnalysisRequestAssetTypeErrorAssetSizeErrorBrandsNotAllowedErrorObjectiveFamiliesNotFoundError = AnalysisRequest | AssetSizeError | AssetTypeError | BrandsNotAllowedError | NotFoundError | ObjectiveFamiliesNotFoundError;

export type NotPermittedError = {
  __typename: 'NotPermittedError';
  message?: Maybe<Scalars['String']>;
};

export type NotReadyError = {
  __typename: 'NotReadyError';
  message?: Maybe<Scalars['String']>;
};

export type ObjectiveFamiliesNotFoundError = {
  __typename: 'ObjectiveFamiliesNotFoundError';
  message?: Maybe<Scalars['String']>;
};

export type ObjectiveFamily = {
  __typename: 'ObjectiveFamily';
  campaignObjectives: Array<Scalars['String']>;
  id: Scalars['ID'];
  liftCount: Scalars['Int'];
  objectiveFamily: Scalars['String'];
};

export type OutstandingAssetScopeFilters = {
  after?: InputMaybe<Scalars['DateTime']>;
  assetTypes?: InputMaybe<Array<AssetType>>;
  brandIds?: InputMaybe<Array<Scalars['String']>>;
  industryIds?: InputMaybe<Array<Scalars['String']>>;
  objectiveFamilyIds?: InputMaybe<Array<Scalars['String']>>;
  platforms?: InputMaybe<Array<Platform>>;
  themeIds?: InputMaybe<Array<Scalars['String']>>;
};

export enum Platform {
  Facebook = 'FACEBOOK',
  Tiktok = 'TIKTOK'
}

export type PredictAsset = {
  __typename: 'PredictAsset';
  assetType: AssetType;
  brand: Brand;
  creativeScore?: Maybe<Scalars['Float']>;
  fileUrl: Scalars['String'];
  id: Scalars['String'];
  industryId: Scalars['String'];
  industryName: Scalars['String'];
  objectiveFamily: ObjectiveFamily;
  platform: Platform;
  thumbnailUrl: Scalars['String'];
};

export type Query = {
  __typename: 'Query';
  ageRanges: Array<AgeRange>;
  analysisGroups: Array<AnalysisGroup>;
  analysisRequests: Array<AnalysisRequest>;
  assetThemes: Array<Theme>;
  assets: Array<Asset>;
  bestPerformerThemes: Array<Theme>;
  bestPerformers: Array<BestPerformer>;
  brandKpis: Array<Kpi>;
  brandThemes: Array<Theme>;
  brands: Array<BrandNotFoundError>;
  brandsConfidences: Array<Confidence>;
  brandsFeatureCategories: Array<FeatureCategory>;
  brandsLearnings: Array<Learning>;
  creativeScoreLearnings: Array<CreativeScoreLearning>;
  featureCategories: Array<AssetFeatureCategory>;
  genders: Array<ScopeGender>;
  getAssetsByBrand: Array<Asset>;
  getAssetsCount: Scalars['Int'];
  hiddenGemThemes: Array<Theme>;
  hiddenGems: Array<HiddenGem>;
  industries: Array<Industry>;
  industriesConfidences: Array<Confidence>;
  industriesFeatureCategories: Array<FeatureCategory>;
  industriesLearnings: Array<Learning>;
  industryKpis: Array<Kpi>;
  industryThemes: Array<Theme>;
  learnings: Array<Learning>;
  message?: Maybe<Scalars['String']>;
  objectiveFamilies: Array<ObjectiveFamily>;
  user: User;
  users: Array<User>;
};


export type QueryAgeRangesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Scalars['String']>>;
  orderDesc?: InputMaybe<Array<Scalars['Boolean']>>;
  parameters?: InputMaybe<AssetQueryParameters>;
};


export type QueryAnalysisGroupsArgs = {
  brandIds?: InputMaybe<Array<Scalars['String']>>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  includeDeleted?: InputMaybe<Scalars['Boolean']>;
};


export type QueryAnalysisRequestsArgs = {
  brandIds?: InputMaybe<Array<Scalars['String']>>;
  groupIds?: InputMaybe<Array<Scalars['String']>>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  includeDeleted?: InputMaybe<Scalars['Boolean']>;
};


export type QueryAssetThemesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Scalars['String']>>;
  orderDesc?: InputMaybe<Array<Scalars['Boolean']>>;
  parameters?: InputMaybe<AssetQueryParameters>;
};


export type QueryAssetsArgs = {
  absentFeatures?: InputMaybe<Array<Scalars['String']>>;
  after?: InputMaybe<Scalars['DateTime']>;
  ageRanges?: InputMaybe<Array<Scalars['String']>>;
  before?: InputMaybe<Scalars['DateTime']>;
  brandIds?: InputMaybe<Array<Scalars['String']>>;
  excludedBrandIds?: InputMaybe<Array<Scalars['String']>>;
  format?: InputMaybe<AssetType>;
  genders?: InputMaybe<Array<Gender>>;
  groupIds?: InputMaybe<Array<Scalars['String']>>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  industryIds?: InputMaybe<Array<Scalars['String']>>;
  industryNames?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
  objectiveFamilyIds?: InputMaybe<Array<Scalars['String']>>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Scalars['String']>>;
  orderDesc?: InputMaybe<Array<Scalars['Boolean']>>;
  platforms?: InputMaybe<Array<Platform>>;
  presentFeatures?: InputMaybe<Array<Scalars['String']>>;
  themeIds?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryBestPerformerThemesArgs = {
  filters: OutstandingAssetScopeFilters;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Scalars['String']>>;
  orderDesc?: InputMaybe<Array<Scalars['Boolean']>>;
};


export type QueryBestPerformersArgs = {
  after: Scalars['DateTime'];
  assetTypes?: InputMaybe<Array<AssetType>>;
  brandIds: Array<Scalars['String']>;
  industryIds: Array<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  objectiveFamilyIds: Array<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Scalars['String']>>;
  orderDesc?: InputMaybe<Array<Scalars['Boolean']>>;
  platforms: Array<Platform>;
  themeIds?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryBrandKpisArgs = {
  filters: BrandScopedLearningFilters;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Scalars['String']>>;
  orderDesc?: InputMaybe<Array<Scalars['Boolean']>>;
};


export type QueryBrandThemesArgs = {
  filters: BrandScopedLearningFilters;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Scalars['String']>>;
  orderDesc?: InputMaybe<Array<Scalars['Boolean']>>;
};


export type QueryBrandsArgs = {
  ids?: InputMaybe<Array<Scalars['String']>>;
  industryIds?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryBrandsConfidencesArgs = {
  filters: BrandScopedLearningFilters;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Scalars['String']>>;
  orderDesc?: InputMaybe<Array<Scalars['Boolean']>>;
};


export type QueryBrandsFeatureCategoriesArgs = {
  filters: BrandScopedLearningFilters;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Scalars['String']>>;
  orderDesc?: InputMaybe<Array<Scalars['Boolean']>>;
};


export type QueryBrandsLearningsArgs = {
  filters: BrandScopedLearningFilters;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Scalars['String']>>;
  orderDesc?: InputMaybe<Array<Scalars['Boolean']>>;
};


export type QueryCreativeScoreLearningsArgs = {
  filters: CreativeScoreLearningsFilters;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Scalars['String']>>;
  orderDesc?: InputMaybe<Array<Scalars['Boolean']>>;
};


export type QueryFeatureCategoriesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Scalars['String']>>;
  orderDesc?: InputMaybe<Array<Scalars['Boolean']>>;
};


export type QueryGendersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Scalars['String']>>;
  orderDesc?: InputMaybe<Array<Scalars['Boolean']>>;
  parameters?: InputMaybe<AssetQueryParameters>;
};


export type QueryGetAssetsByBrandArgs = {
  after?: InputMaybe<Scalars['DateTime']>;
  audience?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['DateTime']>;
  brandId: Scalars['String'];
  format?: InputMaybe<AssetType>;
  industryIds?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Scalars['String']>>;
  orderDesc?: InputMaybe<Array<Scalars['Boolean']>>;
  platforms?: InputMaybe<Array<Platform>>;
  theme?: InputMaybe<Scalars['String']>;
};


export type QueryGetAssetsCountArgs = {
  after?: InputMaybe<Scalars['DateTime']>;
  before?: InputMaybe<Scalars['DateTime']>;
  brandIds: Array<Scalars['String']>;
  format?: InputMaybe<AssetType>;
  industryIds?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
  objectiveFamilyIds?: InputMaybe<Array<Scalars['String']>>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Scalars['String']>>;
  orderDesc?: InputMaybe<Array<Scalars['Boolean']>>;
  platforms?: InputMaybe<Array<Platform>>;
  themeIds?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryHiddenGemThemesArgs = {
  filters: OutstandingAssetScopeFilters;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Scalars['String']>>;
  orderDesc?: InputMaybe<Array<Scalars['Boolean']>>;
};


export type QueryHiddenGemsArgs = {
  after: Scalars['DateTime'];
  assetTypes?: InputMaybe<Array<AssetType>>;
  brandIds: Array<Scalars['String']>;
  industryIds: Array<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  objectiveFamilyIds: Array<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Scalars['String']>>;
  orderDesc?: InputMaybe<Array<Scalars['Boolean']>>;
  platforms: Array<Platform>;
  themeIds?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryIndustriesArgs = {
  ids?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryIndustriesConfidencesArgs = {
  filters: IndustryScopedLearningFilters;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Scalars['String']>>;
  orderDesc?: InputMaybe<Array<Scalars['Boolean']>>;
};


export type QueryIndustriesFeatureCategoriesArgs = {
  filters: IndustryScopedLearningFilters;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Scalars['String']>>;
  orderDesc?: InputMaybe<Array<Scalars['Boolean']>>;
};


export type QueryIndustriesLearningsArgs = {
  filters: IndustryScopedLearningFilters;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Scalars['String']>>;
  orderDesc?: InputMaybe<Array<Scalars['Boolean']>>;
};


export type QueryIndustryKpisArgs = {
  filters: IndustryScopedLearningFilters;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Scalars['String']>>;
  orderDesc?: InputMaybe<Array<Scalars['Boolean']>>;
};


export type QueryIndustryThemesArgs = {
  filters: IndustryScopedLearningFilters;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Scalars['String']>>;
  orderDesc?: InputMaybe<Array<Scalars['Boolean']>>;
};


export type QueryLearningsArgs = {
  ids: Array<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Scalars['String']>>;
  orderDesc?: InputMaybe<Array<Scalars['Boolean']>>;
};


export type QueryObjectiveFamiliesArgs = {
  ids?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Scalars['String']>>;
  orderDesc?: InputMaybe<Array<Scalars['Boolean']>>;
};

export enum Role {
  Admin = 'ADMIN',
  AssetTagger = 'ASSET_TAGGER',
  Saas = 'SAAS',
  Service = 'SERVICE'
}

export type ScopeGender = {
  __typename: 'ScopeGender';
  gender: Gender;
};

export enum Status {
  Analyzing = 'ANALYZING',
  Cancelled = 'CANCELLED',
  Done = 'DONE',
  Draft = 'DRAFT',
  Error = 'ERROR',
  Pending = 'PENDING'
}

export type Success = {
  __typename: 'Success';
  message: Scalars['String'];
};

export type SuccessNotFoundError = NotFoundError | Success;

export type SuccessNotFoundErrorNotPermittedError = NotFoundError | NotPermittedError | Success;

export type Theme = {
  __typename: 'Theme';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type User = {
  __typename: 'User';
  brands: Array<Brand>;
  email: Scalars['String'];
  id: Scalars['String'];
  intercomHash: Scalars['String'];
  name: Scalars['String'];
  roles: Array<Role>;
};

export enum ValuesScope {
  Brands = 'BRANDS',
  CreativeScore = 'CREATIVE_SCORE',
  Industry = 'INDUSTRY'
}

export type AverageCreativeScoresQueryVariables = Exact<{
  brandIds: Array<Scalars['String']> | Scalars['String'];
  industryIds: Array<Scalars['String']> | Scalars['String'];
  platforms?: InputMaybe<Array<Platform> | Platform>;
  objectiveFamilyIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  format?: InputMaybe<AssetType>;
  before?: InputMaybe<Scalars['DateTime']>;
  after?: InputMaybe<Scalars['DateTime']>;
  themeIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  onlyBrand?: InputMaybe<Scalars['Boolean']>;
}>;


export type AverageCreativeScoresQuery = { __typename: 'Query', brands: Array<{ __typename: 'Brand', id: string, averageCreativeScore?: number | null } | { __typename: 'NotFoundError' }>, industries?: Array<{ __typename: 'Industry', id: string, averageCreativeScore?: number | null }> };

export type BrandQueryVariables = Exact<{
  industryIds: Array<Scalars['String']> | Scalars['String'];
  objectiveFamilyIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  platforms?: InputMaybe<Array<Platform> | Platform>;
  format?: InputMaybe<AssetType>;
  before?: InputMaybe<Scalars['DateTime']>;
  after?: InputMaybe<Scalars['DateTime']>;
}>;


export type BrandQuery = { __typename: 'Query', brands: Array<{ __typename: 'Brand', id: string, name: string, assetsCount: number } | { __typename: 'NotFoundError' }> };

export type AnalysisGroupDetailsFragment = { __typename: 'AnalysisGroup', id: any, name: string, status: Status, numAssets: number, createdAt: any, user: { __typename: 'User', id: string, name: string }, analysisRequests: Array<{ __typename: 'AnalysisRequest', id: any, name: string, fileUrl: string, fileType: AssetType, fileSize: number, platform?: Platform | null, isUploading: boolean, industry?: { __typename: 'Industry', id: string, name: string, brands: Array<{ __typename: 'Brand', id: string, name: string }> } | null, brand?: { __typename: 'Brand', id: string, name: string } | null, objectiveFamily?: { __typename: 'ObjectiveFamily', id: string, objectiveFamily: string } | null, assets?: Array<{ __typename: 'PredictAsset', id: string, assetType: AssetType, fileUrl: string, thumbnailUrl: string, creativeScore?: number | null, platform: Platform, industryId: string, industryName: string, objectiveFamily: { __typename: 'ObjectiveFamily', id: string, objectiveFamily: string }, brand: { __typename: 'Brand', id: string, name: string } }> | null }> };

export type AnalysisRequestDetailsFragment = { __typename: 'AnalysisRequest', id: any, name: string, fileUrl: string, fileType: AssetType, fileSize: number, platform?: Platform | null, isUploading: boolean, industry?: { __typename: 'Industry', id: string, name: string, brands: Array<{ __typename: 'Brand', id: string, name: string }> } | null, brand?: { __typename: 'Brand', id: string, name: string } | null, objectiveFamily?: { __typename: 'ObjectiveFamily', id: string, objectiveFamily: string } | null, assets?: Array<{ __typename: 'PredictAsset', id: string, assetType: AssetType, fileUrl: string, thumbnailUrl: string, creativeScore?: number | null, platform: Platform, industryId: string, industryName: string, objectiveFamily: { __typename: 'ObjectiveFamily', id: string, objectiveFamily: string }, brand: { __typename: 'Brand', id: string, name: string } }> | null };

export type IndustryCreativeScoresFragment = { __typename: 'Industry', id: string, averageCreativeScore?: number | null };

export type LearningFragmentFragment = { __typename: 'Learning', id: string, scope: ValuesScope, platform: Platform, createdAt: any, scopeStartedAt: any, scopeEndedAt: any, liftPercent: number, kpiName: string, brandMeasure: number, industryMeasure: number, confidence: string, assets: Array<{ __typename: 'Asset', id: string, fileUrl: string, assetType: AssetType, thumbnailUrl: string }>, industry: { __typename: 'Industry', id: string, name: string, brands: Array<{ __typename: 'Brand', id: string }> }, brand?: { __typename: 'Brand', name: string } | null, objectiveFamily: { __typename: 'ObjectiveFamily', objectiveFamily: string }, interpreterV2: { __typename: 'Interpreter', title: string, tags: Array<string>, impact: { __typename: 'InterpreterPhrase', filledTemplate: string, template: string, interpolatedKeys: Array<string>, interpolatedValues: Array<string> }, detailedVersion: { __typename: 'InterpreterPhrase', template: string, interpolatedKeys: Array<string>, interpolatedValues: Array<string>, filledTemplate: string }, shortVersion: { __typename: 'InterpreterPhrase', filledTemplate: string, interpolatedKeys: Array<string>, interpolatedValues: Array<string> } }, featureCategory?: { __typename: 'FeatureCategory', categoryFrontName: string, categoryFrontDescription: string } | null, themes: Array<{ __typename: 'Theme', id: string, name: string }> };

export type UserPartsFragment = { __typename: 'User', id: string, name: string, email: string, roles: Array<Role>, intercomHash: string, brands: Array<{ __typename: 'Brand', id: string, name: string, industries: Array<{ __typename: 'Industry', id: string, name: string, brands: Array<{ __typename: 'Brand', id: string }> }> }> };

export type GetInspiredQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  orderDesc: Array<Scalars['Boolean']> | Scalars['Boolean'];
  brandIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  excludedBrandIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  industryIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  objectiveFamilyIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  platforms?: InputMaybe<Array<Platform> | Platform>;
  before?: InputMaybe<Scalars['DateTime']>;
  after?: InputMaybe<Scalars['DateTime']>;
  format?: InputMaybe<AssetType>;
  themeIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  ageRanges?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  genders?: InputMaybe<Array<Gender> | Gender>;
  presentFeatures?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  absentFeatures?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetInspiredQuery = { __typename: 'Query', assets: Array<{ __typename: 'Asset', id: string, creativeScore?: number | null, assetType: AssetType, platform: Platform, fileUrl: string, analysisDate: any, industryName: string, brand: { __typename: 'Brand', name: string }, objectiveFamily: { __typename: 'ObjectiveFamily', objectiveFamily: string } }> };

export type AgeRangesQueryVariables = Exact<{
  after?: InputMaybe<Scalars['DateTime']>;
  before?: InputMaybe<Scalars['DateTime']>;
  brandIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  format?: InputMaybe<AssetType>;
  industryIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  objectiveFamilyIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  platforms?: InputMaybe<Array<Platform> | Platform>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  orderDesc?: InputMaybe<Array<Scalars['Boolean']> | Scalars['Boolean']>;
  themeIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type AgeRangesQuery = { __typename: 'Query', ageRanges: Array<{ __typename: 'AgeRange', ageRange: string, name: string }> };

export type AssetThemesQueryFragment = { __typename: 'Query', assetThemes: Array<{ __typename: 'Theme', id: string, name: string }> };

export type AssetThemesQueryVariables = Exact<{
  after?: InputMaybe<Scalars['DateTime']>;
  before?: InputMaybe<Scalars['DateTime']>;
  brandIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  format?: InputMaybe<AssetType>;
  industryIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  objectiveFamilyIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  platforms?: InputMaybe<Array<Platform> | Platform>;
  ageRanges?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type AssetThemesQuery = { __typename: 'Query', assetThemes: Array<{ __typename: 'Theme', id: string, name: string }> };

export type FeatureCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type FeatureCategoriesQuery = { __typename: 'Query', featureCategories: Array<{ __typename: 'AssetFeatureCategory', categoryFrontName: string }> };

export type GendersQueryVariables = Exact<{
  after?: InputMaybe<Scalars['DateTime']>;
  before?: InputMaybe<Scalars['DateTime']>;
  brandIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  format?: InputMaybe<AssetType>;
  industryIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  objectiveFamilyIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  platforms?: InputMaybe<Array<Platform> | Platform>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  orderDesc?: InputMaybe<Array<Scalars['Boolean']> | Scalars['Boolean']>;
  themeIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  ageRanges?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GendersQuery = { __typename: 'Query', genders: Array<{ __typename: 'ScopeGender', gender: Gender }> };

export type BestPerformerThemesQueryVariables = Exact<{
  brandIds: Array<Scalars['String']> | Scalars['String'];
  industryIds: Array<Scalars['String']> | Scalars['String'];
  objectiveFamilyIds: Array<Scalars['String']> | Scalars['String'];
  platforms: Array<Platform> | Platform;
  after: Scalars['DateTime'];
  assetTypes?: InputMaybe<Array<AssetType> | AssetType>;
}>;


export type BestPerformerThemesQuery = { __typename: 'Query', bestPerformerThemes: Array<{ __typename: 'Theme', id: string, name: string }> };

export type BestPerformersQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  brandIds: Array<Scalars['String']> | Scalars['String'];
  industryIds: Array<Scalars['String']> | Scalars['String'];
  objectiveFamilyIds: Array<Scalars['String']> | Scalars['String'];
  platforms: Array<Platform> | Platform;
  after: Scalars['DateTime'];
  assetTypes?: InputMaybe<Array<AssetType> | AssetType>;
  themeIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type BestPerformersQuery = { __typename: 'Query', assets: Array<{ __typename: 'BestPerformer', id: string, totalResult: number, cpa: number, roundedCpa: number, totalSpend: number, nbAds: number, nbCampaigns: number, asset: { __typename: 'Asset', creativeScore?: number | null, fileUrl: string, assetType: AssetType, analysisDate: any } }> };

export type BrandConfidencesQueryVariables = Exact<{
  brandIds: Array<Scalars['String']> | Scalars['String'];
  industryIds: Array<Scalars['String']> | Scalars['String'];
  objectiveFamilyIds: Array<Scalars['String']> | Scalars['String'];
  platforms: Array<Platform> | Platform;
  after: Scalars['DateTime'];
  assetTypes?: InputMaybe<Array<AssetType> | AssetType>;
  featureCategories?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  kpis?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  themeIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type BrandConfidencesQuery = { __typename: 'Query', brandsConfidences: Array<{ __typename: 'Confidence', name: string }> };

export type BrandFeaturesQueryVariables = Exact<{
  brandIds: Array<Scalars['String']> | Scalars['String'];
  industryIds: Array<Scalars['String']> | Scalars['String'];
  objectiveFamilyIds: Array<Scalars['String']> | Scalars['String'];
  platforms: Array<Platform> | Platform;
  after: Scalars['DateTime'];
  assetTypes?: InputMaybe<Array<AssetType> | AssetType>;
  kpis?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  confidences?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  themeIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type BrandFeaturesQuery = { __typename: 'Query', brandsFeatureCategories: Array<{ __typename: 'FeatureCategory', categoryFrontName: string }> };

export type BrandKpisQueryVariables = Exact<{
  brandIds: Array<Scalars['String']> | Scalars['String'];
  industryIds: Array<Scalars['String']> | Scalars['String'];
  objectiveFamilyIds: Array<Scalars['String']> | Scalars['String'];
  platforms: Array<Platform> | Platform;
  after: Scalars['DateTime'];
  assetTypes?: InputMaybe<Array<AssetType> | AssetType>;
  featureCategories?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  confidences?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  themeIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type BrandKpisQuery = { __typename: 'Query', brandKpis: Array<{ __typename: 'KPI', kpi: string, name: string }> };

export type BrandThemesQueryFragment = { __typename: 'Query', brandThemes: Array<{ __typename: 'Theme', id: string, name: string }> };

export type BrandThemesQueryVariables = Exact<{
  brandIds: Array<Scalars['String']> | Scalars['String'];
  industryIds: Array<Scalars['String']> | Scalars['String'];
  objectiveFamilyIds: Array<Scalars['String']> | Scalars['String'];
  platforms: Array<Platform> | Platform;
  after: Scalars['DateTime'];
  assetTypes?: InputMaybe<Array<AssetType> | AssetType>;
  featureCategories?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  kpis?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  confidences?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type BrandThemesQuery = { __typename: 'Query', brandThemes: Array<{ __typename: 'Theme', id: string, name: string }> };

export type HiddenGemThemesQueryVariables = Exact<{
  brandIds: Array<Scalars['String']> | Scalars['String'];
  industryIds: Array<Scalars['String']> | Scalars['String'];
  objectiveFamilyIds: Array<Scalars['String']> | Scalars['String'];
  platforms: Array<Platform> | Platform;
  after: Scalars['DateTime'];
  assetTypes?: InputMaybe<Array<AssetType> | AssetType>;
}>;


export type HiddenGemThemesQuery = { __typename: 'Query', hiddenGemThemes: Array<{ __typename: 'Theme', id: string, name: string }> };

export type HiddenGemsQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  brandIds: Array<Scalars['String']> | Scalars['String'];
  industryIds: Array<Scalars['String']> | Scalars['String'];
  objectiveFamilyIds: Array<Scalars['String']> | Scalars['String'];
  platforms: Array<Platform> | Platform;
  after: Scalars['DateTime'];
  assetTypes?: InputMaybe<Array<AssetType> | AssetType>;
  themeIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type HiddenGemsQuery = { __typename: 'Query', assets: Array<{ __typename: 'HiddenGem', id: string, totalResult: number, roundedCpa: number, totalSpend: number, nbAds: number, nbCampaigns: number, asset: { __typename: 'Asset', creativeScore?: number | null, fileUrl: string, assetType: AssetType, analysisDate: any } }> };

export type IndustryConfidencesQueryVariables = Exact<{
  industryIds: Array<Scalars['String']> | Scalars['String'];
  objectiveFamilyIds: Array<Scalars['String']> | Scalars['String'];
  platforms: Array<Platform> | Platform;
  after: Scalars['DateTime'];
  assetTypes?: InputMaybe<Array<AssetType> | AssetType>;
  featureCategories?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  kpis?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  themeIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type IndustryConfidencesQuery = { __typename: 'Query', industriesConfidences: Array<{ __typename: 'Confidence', name: string }> };

export type IndustryFeaturesQueryVariables = Exact<{
  industryIds: Array<Scalars['String']> | Scalars['String'];
  objectiveFamilyIds: Array<Scalars['String']> | Scalars['String'];
  platforms: Array<Platform> | Platform;
  after: Scalars['DateTime'];
  assetTypes?: InputMaybe<Array<AssetType> | AssetType>;
  kpis?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  confidences?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  themeIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type IndustryFeaturesQuery = { __typename: 'Query', industriesFeatureCategories: Array<{ __typename: 'FeatureCategory', categoryFrontName: string }> };

export type IndustryKpisQueryVariables = Exact<{
  industryIds: Array<Scalars['String']> | Scalars['String'];
  objectiveFamilyIds: Array<Scalars['String']> | Scalars['String'];
  platforms: Array<Platform> | Platform;
  after: Scalars['DateTime'];
  assetTypes?: InputMaybe<Array<AssetType> | AssetType>;
  featureCategories?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  confidences?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  themeIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type IndustryKpisQuery = { __typename: 'Query', industryKpis: Array<{ __typename: 'KPI', kpi: string, name: string }> };

export type IndustryLearningsQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  industryIds: Array<Scalars['String']> | Scalars['String'];
  objectiveFamilyIds: Array<Scalars['String']> | Scalars['String'];
  platforms: Array<Platform> | Platform;
  after: Scalars['DateTime'];
  assetTypes?: InputMaybe<Array<AssetType> | AssetType>;
  featureCategories?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  kpis?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  confidences?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  themeIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type IndustryLearningsQuery = { __typename: 'Query', learnings: Array<{ __typename: 'Learning', id: string, scope: ValuesScope, platform: Platform, createdAt: any, scopeStartedAt: any, scopeEndedAt: any, liftPercent: number, kpiName: string, brandMeasure: number, industryMeasure: number, confidence: string, assets: Array<{ __typename: 'Asset', id: string, fileUrl: string, assetType: AssetType, thumbnailUrl: string }>, industry: { __typename: 'Industry', id: string, name: string, brands: Array<{ __typename: 'Brand', id: string }> }, brand?: { __typename: 'Brand', name: string } | null, objectiveFamily: { __typename: 'ObjectiveFamily', objectiveFamily: string }, interpreterV2: { __typename: 'Interpreter', title: string, tags: Array<string>, impact: { __typename: 'InterpreterPhrase', filledTemplate: string, template: string, interpolatedKeys: Array<string>, interpolatedValues: Array<string> }, detailedVersion: { __typename: 'InterpreterPhrase', template: string, interpolatedKeys: Array<string>, interpolatedValues: Array<string>, filledTemplate: string }, shortVersion: { __typename: 'InterpreterPhrase', filledTemplate: string, interpolatedKeys: Array<string>, interpolatedValues: Array<string> } }, featureCategory?: { __typename: 'FeatureCategory', categoryFrontName: string, categoryFrontDescription: string } | null, themes: Array<{ __typename: 'Theme', id: string, name: string }> }> };

export type IndustryThemesQueryFragment = { __typename: 'Query', industryThemes: Array<{ __typename: 'Theme', id: string, name: string }> };

export type IndustryThemesQueryVariables = Exact<{
  industryIds: Array<Scalars['String']> | Scalars['String'];
  objectiveFamilyIds: Array<Scalars['String']> | Scalars['String'];
  platforms: Array<Platform> | Platform;
  after: Scalars['DateTime'];
  assetTypes?: InputMaybe<Array<AssetType> | AssetType>;
  featureCategories?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  kpis?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  confidences?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type IndustryThemesQuery = { __typename: 'Query', industryThemes: Array<{ __typename: 'Theme', id: string, name: string }> };

export type InspirationsQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  industryIds: Array<Scalars['String']> | Scalars['String'];
  brandIds: Array<Scalars['String']> | Scalars['String'];
  objectiveFamilyIds: Array<Scalars['String']> | Scalars['String'];
  platforms: Array<Platform> | Platform;
  after: Scalars['DateTime'];
  format?: InputMaybe<AssetType>;
  themeIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type InspirationsQuery = { __typename: 'Query', assets: Array<{ __typename: 'Asset', id: string, fileUrl: string, creativeScore?: number | null, assetType: AssetType, spend: number, analysisDate: any, platform: Platform, brand: { __typename: 'Brand', name: string } }> };

export type LearnThemesQueryVariables = Exact<{
  after?: InputMaybe<Scalars['DateTime']>;
  before?: InputMaybe<Scalars['DateTime']>;
  brandIds: Array<Scalars['String']> | Scalars['String'];
  industryIds: Array<Scalars['String']> | Scalars['String'];
  objectiveFamilyIds: Array<Scalars['String']> | Scalars['String'];
  platforms: Array<Platform> | Platform;
  assetTypes?: InputMaybe<Array<AssetType> | AssetType>;
  format?: InputMaybe<AssetType>;
  kpis?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  confidences?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  ageRanges?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  featureCategories?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type LearnThemesQuery = { __typename: 'Query', brandThemes: Array<{ __typename: 'Theme', id: string, name: string }>, industryThemes: Array<{ __typename: 'Theme', id: string, name: string }>, assetThemes: Array<{ __typename: 'Theme', id: string, name: string }> };

export type LearningsQueryVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;


export type LearningsQuery = { __typename: 'Query', learnings: Array<{ __typename: 'Learning', id: string, scope: ValuesScope, platform: Platform, createdAt: any, scopeStartedAt: any, scopeEndedAt: any, liftPercent: number, kpiName: string, brandMeasure: number, industryMeasure: number, confidence: string, assets: Array<{ __typename: 'Asset', id: string, fileUrl: string, assetType: AssetType, thumbnailUrl: string }>, industry: { __typename: 'Industry', id: string, name: string, brands: Array<{ __typename: 'Brand', id: string }> }, brand?: { __typename: 'Brand', name: string } | null, objectiveFamily: { __typename: 'ObjectiveFamily', objectiveFamily: string }, interpreterV2: { __typename: 'Interpreter', title: string, tags: Array<string>, impact: { __typename: 'InterpreterPhrase', filledTemplate: string, template: string, interpolatedKeys: Array<string>, interpolatedValues: Array<string> }, detailedVersion: { __typename: 'InterpreterPhrase', template: string, interpolatedKeys: Array<string>, interpolatedValues: Array<string>, filledTemplate: string }, shortVersion: { __typename: 'InterpreterPhrase', filledTemplate: string, interpolatedKeys: Array<string>, interpolatedValues: Array<string> } }, featureCategory?: { __typename: 'FeatureCategory', categoryFrontName: string, categoryFrontDescription: string } | null, themes: Array<{ __typename: 'Theme', id: string, name: string }> }> };

export type MyLearningsQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  brandIds: Array<Scalars['String']> | Scalars['String'];
  industryIds: Array<Scalars['String']> | Scalars['String'];
  objectiveFamilyIds: Array<Scalars['String']> | Scalars['String'];
  platforms: Array<Platform> | Platform;
  after: Scalars['DateTime'];
  assetTypes?: InputMaybe<Array<AssetType> | AssetType>;
  featureCategories?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  kpis?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  confidences?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  themeIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type MyLearningsQuery = { __typename: 'Query', learnings: Array<{ __typename: 'Learning', id: string, scope: ValuesScope, platform: Platform, createdAt: any, scopeStartedAt: any, scopeEndedAt: any, liftPercent: number, kpiName: string, brandMeasure: number, industryMeasure: number, confidence: string, assets: Array<{ __typename: 'Asset', id: string, fileUrl: string, assetType: AssetType, thumbnailUrl: string }>, industry: { __typename: 'Industry', id: string, name: string, brands: Array<{ __typename: 'Brand', id: string }> }, brand?: { __typename: 'Brand', name: string } | null, objectiveFamily: { __typename: 'ObjectiveFamily', objectiveFamily: string }, interpreterV2: { __typename: 'Interpreter', title: string, tags: Array<string>, impact: { __typename: 'InterpreterPhrase', filledTemplate: string, template: string, interpolatedKeys: Array<string>, interpolatedValues: Array<string> }, detailedVersion: { __typename: 'InterpreterPhrase', template: string, interpolatedKeys: Array<string>, interpolatedValues: Array<string>, filledTemplate: string }, shortVersion: { __typename: 'InterpreterPhrase', filledTemplate: string, interpolatedKeys: Array<string>, interpolatedValues: Array<string> } }, featureCategory?: { __typename: 'FeatureCategory', categoryFrontName: string, categoryFrontDescription: string } | null, themes: Array<{ __typename: 'Theme', id: string, name: string }> }> };

export type ObjectiveFamiliesQueryVariables = Exact<{ [key: string]: never; }>;


export type ObjectiveFamiliesQuery = { __typename: 'Query', objectiveFamilies: Array<{ __typename: 'ObjectiveFamily', id: string, objectiveFamily: string }> };

export type AnalysisCriteriasQueryVariables = Exact<{
  assetIds: Array<Scalars['String']> | Scalars['String'];
  industryIds: Array<Scalars['String']> | Scalars['String'];
  platforms: Array<Platform> | Platform;
  objectiveFamilyIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type AnalysisCriteriasQuery = { __typename: 'Query', learnings: Array<{ __typename: 'CreativeScoreLearning', liftId: string, assetId: string, industryId: string, platform: Platform, objectiveFamilyId: string, weight: number, isMatchingLift: boolean, aggregationKey?: string | null, learning: { __typename: 'Learning', id: string, scope: ValuesScope, platform: Platform, createdAt: any, scopeStartedAt: any, scopeEndedAt: any, liftPercent: number, kpiName: string, brandMeasure: number, industryMeasure: number, confidence: string, assets: Array<{ __typename: 'Asset', id: string, fileUrl: string, assetType: AssetType, thumbnailUrl: string }>, industry: { __typename: 'Industry', id: string, name: string, brands: Array<{ __typename: 'Brand', id: string }> }, brand?: { __typename: 'Brand', name: string } | null, objectiveFamily: { __typename: 'ObjectiveFamily', objectiveFamily: string }, interpreterV2: { __typename: 'Interpreter', title: string, tags: Array<string>, impact: { __typename: 'InterpreterPhrase', filledTemplate: string, template: string, interpolatedKeys: Array<string>, interpolatedValues: Array<string> }, detailedVersion: { __typename: 'InterpreterPhrase', template: string, interpolatedKeys: Array<string>, interpolatedValues: Array<string>, filledTemplate: string }, shortVersion: { __typename: 'InterpreterPhrase', filledTemplate: string, interpolatedKeys: Array<string>, interpolatedValues: Array<string> } }, featureCategory?: { __typename: 'FeatureCategory', categoryFrontName: string, categoryFrontDescription: string } | null, themes: Array<{ __typename: 'Theme', id: string, name: string }> } }> };

export type AnalysisGroupDetailQueryVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;


export type AnalysisGroupDetailQuery = { __typename: 'Query', analysisGroups: Array<{ __typename: 'AnalysisGroup', id: any, name: string, status: Status, numAssets: number, createdAt: any, user: { __typename: 'User', id: string, name: string }, analysisRequests: Array<{ __typename: 'AnalysisRequest', id: any, name: string, fileUrl: string, fileType: AssetType, fileSize: number, platform?: Platform | null, isUploading: boolean, industry?: { __typename: 'Industry', id: string, name: string, brands: Array<{ __typename: 'Brand', id: string, name: string }> } | null, brand?: { __typename: 'Brand', id: string, name: string } | null, objectiveFamily?: { __typename: 'ObjectiveFamily', id: string, objectiveFamily: string } | null, assets?: Array<{ __typename: 'PredictAsset', id: string, assetType: AssetType, fileUrl: string, thumbnailUrl: string, creativeScore?: number | null, platform: Platform, industryId: string, industryName: string, objectiveFamily: { __typename: 'ObjectiveFamily', id: string, objectiveFamily: string }, brand: { __typename: 'Brand', id: string, name: string } }> | null }> }> };

export type AnalysisGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type AnalysisGroupsQuery = { __typename: 'Query', analysisGroups: Array<{ __typename: 'AnalysisGroup', id: any, name: string, status: Status, numAssets: number, createdAt: any, user: { __typename: 'User', id: string, name: string }, analysisRequests: Array<{ __typename: 'AnalysisRequest', id: any, name: string, fileUrl: string, fileType: AssetType, fileSize: number, platform?: Platform | null, isUploading: boolean, industry?: { __typename: 'Industry', id: string, name: string, brands: Array<{ __typename: 'Brand', id: string, name: string }> } | null, brand?: { __typename: 'Brand', id: string, name: string } | null, objectiveFamily?: { __typename: 'ObjectiveFamily', id: string, objectiveFamily: string } | null, assets?: Array<{ __typename: 'PredictAsset', id: string, assetType: AssetType, fileUrl: string, thumbnailUrl: string, creativeScore?: number | null, platform: Platform, industryId: string, industryName: string, objectiveFamily: { __typename: 'ObjectiveFamily', id: string, objectiveFamily: string }, brand: { __typename: 'Brand', id: string, name: string } }> | null }> }> };

export type CreateAnalysisGroupMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type CreateAnalysisGroupMutation = { __typename: 'AnalysisMutation', createAnalysisGroup: { __typename: 'AnalysisGroup', id: any, name: string, status: Status, numAssets: number, createdAt: any, user: { __typename: 'User', id: string, name: string }, analysisRequests: Array<{ __typename: 'AnalysisRequest', id: any, name: string, fileUrl: string, fileType: AssetType, fileSize: number, platform?: Platform | null, isUploading: boolean, industry?: { __typename: 'Industry', id: string, name: string, brands: Array<{ __typename: 'Brand', id: string, name: string }> } | null, brand?: { __typename: 'Brand', id: string, name: string } | null, objectiveFamily?: { __typename: 'ObjectiveFamily', id: string, objectiveFamily: string } | null, assets?: Array<{ __typename: 'PredictAsset', id: string, assetType: AssetType, fileUrl: string, thumbnailUrl: string, creativeScore?: number | null, platform: Platform, industryId: string, industryName: string, objectiveFamily: { __typename: 'ObjectiveFamily', id: string, objectiveFamily: string }, brand: { __typename: 'Brand', id: string, name: string } }> | null }> } };

export type CreateAnalysisRequestMutationVariables = Exact<{
  createInputs: Array<AnalysisCreateInput> | AnalysisCreateInput;
}>;


export type CreateAnalysisRequestMutation = { __typename: 'AnalysisMutation', createAnalysisRequest: Array<{ __typename: 'AnalysisRequest', id: any, name: string, fileUrl: string, fileType: AssetType, fileSize: number, platform?: Platform | null, isUploading: boolean, industry?: { __typename: 'Industry', id: string, name: string, brands: Array<{ __typename: 'Brand', id: string, name: string }> } | null, brand?: { __typename: 'Brand', id: string, name: string } | null, objectiveFamily?: { __typename: 'ObjectiveFamily', id: string, objectiveFamily: string } | null, assets?: Array<{ __typename: 'PredictAsset', id: string, assetType: AssetType, fileUrl: string, thumbnailUrl: string, creativeScore?: number | null, platform: Platform, industryId: string, industryName: string, objectiveFamily: { __typename: 'ObjectiveFamily', id: string, objectiveFamily: string }, brand: { __typename: 'Brand', id: string, name: string } }> | null } | { __typename: 'AssetSizeError', fileName: string, maximumFileSize: string } | { __typename: 'AssetTypeError', message?: string | null } | { __typename: 'BrandsNotAllowedError', message?: string | null } | { __typename: 'ObjectiveFamiliesNotFoundError', message?: string | null }> };

export type DeleteAnalysisGroupMutationVariables = Exact<{
  analysisGroupId: Scalars['String'];
}>;


export type DeleteAnalysisGroupMutation = { __typename: 'AnalysisMutation', deleteAnalysisGroup: { __typename: 'NotFoundError' } | { __typename: 'NotPermittedError' } | { __typename: 'Success', message: string } };

export type DeleteAnalysisRequestMutationVariables = Exact<{
  analysisRequestId: Scalars['String'];
}>;


export type DeleteAnalysisRequestMutation = { __typename: 'AnalysisMutation', deleteAnalysisRequest: { __typename: 'NotFoundError', message?: string | null } | { __typename: 'Success', sucessMessage: string } };

export type RenameAnalysisMutationVariables = Exact<{
  analysisId: Scalars['String'];
  name: Scalars['String'];
}>;


export type RenameAnalysisMutation = { __typename: 'AnalysisMutation', updateAnalysisGroup: { __typename: 'AnalysisGroup', id: any, name: string, status: Status, numAssets: number, createdAt: any, user: { __typename: 'User', id: string, name: string }, analysisRequests: Array<{ __typename: 'AnalysisRequest', id: any, name: string, fileUrl: string, fileType: AssetType, fileSize: number, platform?: Platform | null, isUploading: boolean, industry?: { __typename: 'Industry', id: string, name: string, brands: Array<{ __typename: 'Brand', id: string, name: string }> } | null, brand?: { __typename: 'Brand', id: string, name: string } | null, objectiveFamily?: { __typename: 'ObjectiveFamily', id: string, objectiveFamily: string } | null, assets?: Array<{ __typename: 'PredictAsset', id: string, assetType: AssetType, fileUrl: string, thumbnailUrl: string, creativeScore?: number | null, platform: Platform, industryId: string, industryName: string, objectiveFamily: { __typename: 'ObjectiveFamily', id: string, objectiveFamily: string }, brand: { __typename: 'Brand', id: string, name: string } }> | null }> } | { __typename: 'NotFoundError', message?: string | null } };

export type StartAnalysisGroupMutationVariables = Exact<{
  analysisGroupId: Scalars['String'];
}>;


export type StartAnalysisGroupMutation = { __typename: 'AnalysisMutation', startAnalysisGroup: { __typename: 'AnalysisGroup', id: any, name: string, status: Status, numAssets: number, createdAt: any, user: { __typename: 'User', id: string, name: string }, analysisRequests: Array<{ __typename: 'AnalysisRequest', id: any, name: string, fileUrl: string, fileType: AssetType, fileSize: number, platform?: Platform | null, isUploading: boolean, industry?: { __typename: 'Industry', id: string, name: string, brands: Array<{ __typename: 'Brand', id: string, name: string }> } | null, brand?: { __typename: 'Brand', id: string, name: string } | null, objectiveFamily?: { __typename: 'ObjectiveFamily', id: string, objectiveFamily: string } | null, assets?: Array<{ __typename: 'PredictAsset', id: string, assetType: AssetType, fileUrl: string, thumbnailUrl: string, creativeScore?: number | null, platform: Platform, industryId: string, industryName: string, objectiveFamily: { __typename: 'ObjectiveFamily', id: string, objectiveFamily: string }, brand: { __typename: 'Brand', id: string, name: string } }> | null }> } | { __typename: 'NotReadyError' } };

export type UpdateAnalysisRequestMutationVariables = Exact<{
  analysisRequestId: Scalars['String'];
  brand?: InputMaybe<Scalars['String']>;
  objectiveFamily?: InputMaybe<Scalars['String']>;
  platform?: InputMaybe<Platform>;
  industry?: InputMaybe<Scalars['String']>;
}>;


export type UpdateAnalysisRequestMutation = { __typename: 'AnalysisMutation', updateAnalysisRequest: { __typename: 'AnalysisRequest', id: any, name: string, fileUrl: string, fileType: AssetType, fileSize: number, platform?: Platform | null, isUploading: boolean, industry?: { __typename: 'Industry', id: string, name: string, brands: Array<{ __typename: 'Brand', id: string, name: string }> } | null, brand?: { __typename: 'Brand', id: string, name: string } | null, objectiveFamily?: { __typename: 'ObjectiveFamily', id: string, objectiveFamily: string } | null, assets?: Array<{ __typename: 'PredictAsset', id: string, assetType: AssetType, fileUrl: string, thumbnailUrl: string, creativeScore?: number | null, platform: Platform, industryId: string, industryName: string, objectiveFamily: { __typename: 'ObjectiveFamily', id: string, objectiveFamily: string }, brand: { __typename: 'Brand', id: string, name: string } }> | null } | { __typename: 'AssetSizeError', fileName: string, maximumFileSize: string } | { __typename: 'AssetTypeError', message?: string | null } | { __typename: 'BrandsNotAllowedError', message?: string | null } | { __typename: 'NotFoundError', message?: string | null } | { __typename: 'ObjectiveFamiliesNotFoundError', message?: string | null } };

export type UserQueryVariables = Exact<{ [key: string]: never; }>;


export type UserQuery = { __typename: 'Query', user: { __typename: 'User', id: string, name: string, email: string, roles: Array<Role>, intercomHash: string, brands: Array<{ __typename: 'Brand', id: string, name: string, industries: Array<{ __typename: 'Industry', id: string, name: string, brands: Array<{ __typename: 'Brand', id: string }> }> }> } };

export const AnalysisRequestDetailsFragmentDoc = gql`
    fragment AnalysisRequestDetails on AnalysisRequest {
  id
  name
  fileUrl
  fileType
  fileSize
  platform
  industry {
    id
    name
    brands {
      id
      name
    }
  }
  brand {
    ... on Brand {
      id
      name
    }
  }
  objectiveFamily {
    id
    objectiveFamily
  }
  assets {
    id
    assetType
    fileUrl
    thumbnailUrl
    creativeScore
    objectiveFamily {
      id
      objectiveFamily
    }
    brand {
      id
      name
    }
    platform
    industryId
    industryName
  }
  isUploading @client
}
    `;
export const AnalysisGroupDetailsFragmentDoc = gql`
    fragment AnalysisGroupDetails on AnalysisGroup {
  id
  name
  status
  numAssets
  createdAt
  user {
    id
    name
  }
  analysisRequests {
    ...AnalysisRequestDetails
  }
}
    ${AnalysisRequestDetailsFragmentDoc}`;
export const IndustryCreativeScoresFragmentDoc = gql`
    fragment IndustryCreativeScores on Industry {
  id
  averageCreativeScore(
    parameters: {platforms: $platforms, objectiveFamilyIds: $objectiveFamilyIds, format: $format, before: $before, after: $after, themeIds: $themeIds}
  )
}
    `;
export const LearningFragmentFragmentDoc = gql`
    fragment LearningFragment on Learning {
  id
  scope
  assets {
    id
    fileUrl
    assetType
    thumbnailUrl
  }
  industry {
    id
    name
    brands {
      id
    }
  }
  brand {
    name
  }
  objectiveFamily {
    objectiveFamily
  }
  interpreterV2 {
    title
    impact {
      filledTemplate
      template
      interpolatedKeys
      interpolatedValues
    }
    detailedVersion {
      template
      interpolatedKeys
      interpolatedValues
      filledTemplate
    }
    shortVersion {
      filledTemplate
      interpolatedKeys
      interpolatedValues
    }
    tags
  }
  platform
  createdAt
  scopeStartedAt
  scopeEndedAt
  liftPercent
  kpiName
  brandMeasure
  industryMeasure
  confidence
  featureCategory {
    categoryFrontName
    categoryFrontDescription
  }
  themes {
    id
    name
  }
}
    `;
export const UserPartsFragmentDoc = gql`
    fragment UserParts on User {
  id
  name
  email
  roles
  intercomHash
  brands {
    id
    name
    industries {
      id
      name
      brands {
        id
      }
    }
  }
}
    `;
export const AssetThemesQueryFragmentDoc = gql`
    fragment AssetThemesQuery on Query {
  assetThemes(
    parameters: {after: $after, before: $before, brandIds: $brandIds, format: $format, industryIds: $industryIds, objectiveFamilyIds: $objectiveFamilyIds, platforms: $platforms, ageRanges: $ageRanges}
  ) {
    id
    name
  }
}
    `;
export const BrandThemesQueryFragmentDoc = gql`
    fragment BrandThemesQuery on Query {
  brandThemes(
    filters: {industryIds: $industryIds, brandIds: $brandIds, assetTypes: $assetTypes, objectiveFamilyIds: $objectiveFamilyIds, after: $after, platforms: $platforms, featureCategories: $featureCategories, kpis: $kpis, confidences: $confidences}
  ) {
    id
    name
  }
}
    `;
export const IndustryThemesQueryFragmentDoc = gql`
    fragment IndustryThemesQuery on Query {
  industryThemes(
    filters: {industryIds: $industryIds, assetTypes: $assetTypes, objectiveFamilyIds: $objectiveFamilyIds, after: $after, platforms: $platforms, featureCategories: $featureCategories, kpis: $kpis, confidences: $confidences}
  ) {
    id
    name
  }
}
    `;
export const AverageCreativeScoresDocument = gql`
    query averageCreativeScores($brandIds: [String!]!, $industryIds: [String!]!, $platforms: [Platform!], $objectiveFamilyIds: [String!], $format: AssetType, $before: DateTime, $after: DateTime, $themeIds: [String!], $onlyBrand: Boolean = false) {
  brands(ids: $brandIds) {
    ... on Brand {
      id
      averageCreativeScore(
        parameters: {platforms: $platforms, objectiveFamilyIds: $objectiveFamilyIds, format: $format, before: $before, after: $after, themeIds: $themeIds}
      )
    }
  }
  industries(ids: $industryIds) @skip(if: $onlyBrand) {
    ...IndustryCreativeScores
  }
}
    ${IndustryCreativeScoresFragmentDoc}`;

/**
 * __useAverageCreativeScoresQuery__
 *
 * To run a query within a React component, call `useAverageCreativeScoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useAverageCreativeScoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAverageCreativeScoresQuery({
 *   variables: {
 *      brandIds: // value for 'brandIds'
 *      industryIds: // value for 'industryIds'
 *      platforms: // value for 'platforms'
 *      objectiveFamilyIds: // value for 'objectiveFamilyIds'
 *      format: // value for 'format'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      themeIds: // value for 'themeIds'
 *      onlyBrand: // value for 'onlyBrand'
 *   },
 * });
 */
export function useAverageCreativeScoresQuery(baseOptions: Apollo.QueryHookOptions<AverageCreativeScoresQuery, AverageCreativeScoresQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AverageCreativeScoresQuery, AverageCreativeScoresQueryVariables>(AverageCreativeScoresDocument, options);
      }
export function useAverageCreativeScoresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AverageCreativeScoresQuery, AverageCreativeScoresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AverageCreativeScoresQuery, AverageCreativeScoresQueryVariables>(AverageCreativeScoresDocument, options);
        }
export type AverageCreativeScoresQueryHookResult = ReturnType<typeof useAverageCreativeScoresQuery>;
export type AverageCreativeScoresLazyQueryHookResult = ReturnType<typeof useAverageCreativeScoresLazyQuery>;
export type AverageCreativeScoresQueryResult = Apollo.QueryResult<AverageCreativeScoresQuery, AverageCreativeScoresQueryVariables>;
export const BrandDocument = gql`
    query Brand($industryIds: [String!]!, $objectiveFamilyIds: [String!], $platforms: [Platform!], $format: AssetType, $before: DateTime, $after: DateTime) {
  brands(industryIds: $industryIds) {
    ... on Brand {
      id
      name
      assetsCount(
        format: $format
        objectiveFamilyIds: $objectiveFamilyIds
        before: $before
        after: $after
        platforms: $platforms
      )
    }
  }
}
    `;

/**
 * __useBrandQuery__
 *
 * To run a query within a React component, call `useBrandQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandQuery({
 *   variables: {
 *      industryIds: // value for 'industryIds'
 *      objectiveFamilyIds: // value for 'objectiveFamilyIds'
 *      platforms: // value for 'platforms'
 *      format: // value for 'format'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useBrandQuery(baseOptions: Apollo.QueryHookOptions<BrandQuery, BrandQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrandQuery, BrandQueryVariables>(BrandDocument, options);
      }
export function useBrandLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrandQuery, BrandQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrandQuery, BrandQueryVariables>(BrandDocument, options);
        }
export type BrandQueryHookResult = ReturnType<typeof useBrandQuery>;
export type BrandLazyQueryHookResult = ReturnType<typeof useBrandLazyQuery>;
export type BrandQueryResult = Apollo.QueryResult<BrandQuery, BrandQueryVariables>;
export const GetInspiredDocument = gql`
    query GetInspired($offset: Int, $limit: Int, $orderBy: [String!], $orderDesc: [Boolean!]!, $brandIds: [String!], $excludedBrandIds: [String!], $industryIds: [String!], $objectiveFamilyIds: [String!], $platforms: [Platform!], $before: DateTime, $after: DateTime, $format: AssetType, $themeIds: [String!], $ageRanges: [String!], $genders: [Gender!], $presentFeatures: [String!], $absentFeatures: [String!]) {
  assets(
    brandIds: $brandIds
    excludedBrandIds: $excludedBrandIds
    orderBy: $orderBy
    orderDesc: $orderDesc
    format: $format
    themeIds: $themeIds
    ageRanges: $ageRanges
    industryIds: $industryIds
    objectiveFamilyIds: $objectiveFamilyIds
    before: $before
    after: $after
    platforms: $platforms
    genders: $genders
    presentFeatures: $presentFeatures
    absentFeatures: $absentFeatures
    limit: $limit
    offset: $offset
  ) {
    id
    creativeScore
    assetType
    platform
    fileUrl
    analysisDate
    brand {
      name
    }
    industryName
    objectiveFamily {
      objectiveFamily
    }
  }
}
    `;

/**
 * __useGetInspiredQuery__
 *
 * To run a query within a React component, call `useGetInspiredQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInspiredQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInspiredQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *      orderDesc: // value for 'orderDesc'
 *      brandIds: // value for 'brandIds'
 *      excludedBrandIds: // value for 'excludedBrandIds'
 *      industryIds: // value for 'industryIds'
 *      objectiveFamilyIds: // value for 'objectiveFamilyIds'
 *      platforms: // value for 'platforms'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      format: // value for 'format'
 *      themeIds: // value for 'themeIds'
 *      ageRanges: // value for 'ageRanges'
 *      genders: // value for 'genders'
 *      presentFeatures: // value for 'presentFeatures'
 *      absentFeatures: // value for 'absentFeatures'
 *   },
 * });
 */
export function useGetInspiredQuery(baseOptions: Apollo.QueryHookOptions<GetInspiredQuery, GetInspiredQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInspiredQuery, GetInspiredQueryVariables>(GetInspiredDocument, options);
      }
export function useGetInspiredLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInspiredQuery, GetInspiredQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInspiredQuery, GetInspiredQueryVariables>(GetInspiredDocument, options);
        }
export type GetInspiredQueryHookResult = ReturnType<typeof useGetInspiredQuery>;
export type GetInspiredLazyQueryHookResult = ReturnType<typeof useGetInspiredLazyQuery>;
export type GetInspiredQueryResult = Apollo.QueryResult<GetInspiredQuery, GetInspiredQueryVariables>;
export const AgeRangesDocument = gql`
    query AgeRanges($after: DateTime, $before: DateTime, $brandIds: [String!], $format: AssetType, $industryIds: [String!], $objectiveFamilyIds: [String!], $platforms: [Platform!], $limit: Int, $offset: Int, $orderBy: [String!], $orderDesc: [Boolean!], $themeIds: [String!]) {
  ageRanges(
    parameters: {after: $after, before: $before, brandIds: $brandIds, format: $format, industryIds: $industryIds, objectiveFamilyIds: $objectiveFamilyIds, platforms: $platforms, themeIds: $themeIds}
    limit: $limit
    offset: $offset
    orderBy: $orderBy
    orderDesc: $orderDesc
  ) {
    ageRange
    name
  }
}
    `;

/**
 * __useAgeRangesQuery__
 *
 * To run a query within a React component, call `useAgeRangesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgeRangesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgeRangesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      brandIds: // value for 'brandIds'
 *      format: // value for 'format'
 *      industryIds: // value for 'industryIds'
 *      objectiveFamilyIds: // value for 'objectiveFamilyIds'
 *      platforms: // value for 'platforms'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      orderDesc: // value for 'orderDesc'
 *      themeIds: // value for 'themeIds'
 *   },
 * });
 */
export function useAgeRangesQuery(baseOptions?: Apollo.QueryHookOptions<AgeRangesQuery, AgeRangesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AgeRangesQuery, AgeRangesQueryVariables>(AgeRangesDocument, options);
      }
export function useAgeRangesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AgeRangesQuery, AgeRangesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AgeRangesQuery, AgeRangesQueryVariables>(AgeRangesDocument, options);
        }
export type AgeRangesQueryHookResult = ReturnType<typeof useAgeRangesQuery>;
export type AgeRangesLazyQueryHookResult = ReturnType<typeof useAgeRangesLazyQuery>;
export type AgeRangesQueryResult = Apollo.QueryResult<AgeRangesQuery, AgeRangesQueryVariables>;
export const AssetThemesDocument = gql`
    query AssetThemes($after: DateTime, $before: DateTime, $brandIds: [String!], $format: AssetType, $industryIds: [String!], $objectiveFamilyIds: [String!], $platforms: [Platform!], $ageRanges: [String!]) {
  ...AssetThemesQuery
}
    ${AssetThemesQueryFragmentDoc}`;

/**
 * __useAssetThemesQuery__
 *
 * To run a query within a React component, call `useAssetThemesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetThemesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetThemesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      brandIds: // value for 'brandIds'
 *      format: // value for 'format'
 *      industryIds: // value for 'industryIds'
 *      objectiveFamilyIds: // value for 'objectiveFamilyIds'
 *      platforms: // value for 'platforms'
 *      ageRanges: // value for 'ageRanges'
 *   },
 * });
 */
export function useAssetThemesQuery(baseOptions?: Apollo.QueryHookOptions<AssetThemesQuery, AssetThemesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssetThemesQuery, AssetThemesQueryVariables>(AssetThemesDocument, options);
      }
export function useAssetThemesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssetThemesQuery, AssetThemesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssetThemesQuery, AssetThemesQueryVariables>(AssetThemesDocument, options);
        }
export type AssetThemesQueryHookResult = ReturnType<typeof useAssetThemesQuery>;
export type AssetThemesLazyQueryHookResult = ReturnType<typeof useAssetThemesLazyQuery>;
export type AssetThemesQueryResult = Apollo.QueryResult<AssetThemesQuery, AssetThemesQueryVariables>;
export const FeatureCategoriesDocument = gql`
    query FeatureCategories {
  featureCategories {
    categoryFrontName
  }
}
    `;

/**
 * __useFeatureCategoriesQuery__
 *
 * To run a query within a React component, call `useFeatureCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFeatureCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<FeatureCategoriesQuery, FeatureCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeatureCategoriesQuery, FeatureCategoriesQueryVariables>(FeatureCategoriesDocument, options);
      }
export function useFeatureCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeatureCategoriesQuery, FeatureCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeatureCategoriesQuery, FeatureCategoriesQueryVariables>(FeatureCategoriesDocument, options);
        }
export type FeatureCategoriesQueryHookResult = ReturnType<typeof useFeatureCategoriesQuery>;
export type FeatureCategoriesLazyQueryHookResult = ReturnType<typeof useFeatureCategoriesLazyQuery>;
export type FeatureCategoriesQueryResult = Apollo.QueryResult<FeatureCategoriesQuery, FeatureCategoriesQueryVariables>;
export const GendersDocument = gql`
    query Genders($after: DateTime, $before: DateTime, $brandIds: [String!], $format: AssetType, $industryIds: [String!], $objectiveFamilyIds: [String!], $platforms: [Platform!], $limit: Int, $offset: Int, $orderBy: [String!], $orderDesc: [Boolean!], $themeIds: [String!], $ageRanges: [String!]) {
  genders(
    parameters: {after: $after, before: $before, brandIds: $brandIds, format: $format, industryIds: $industryIds, objectiveFamilyIds: $objectiveFamilyIds, platforms: $platforms, themeIds: $themeIds, ageRanges: $ageRanges}
    limit: $limit
    offset: $offset
    orderBy: $orderBy
    orderDesc: $orderDesc
  ) {
    gender
  }
}
    `;

/**
 * __useGendersQuery__
 *
 * To run a query within a React component, call `useGendersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGendersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGendersQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      brandIds: // value for 'brandIds'
 *      format: // value for 'format'
 *      industryIds: // value for 'industryIds'
 *      objectiveFamilyIds: // value for 'objectiveFamilyIds'
 *      platforms: // value for 'platforms'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      orderDesc: // value for 'orderDesc'
 *      themeIds: // value for 'themeIds'
 *      ageRanges: // value for 'ageRanges'
 *   },
 * });
 */
export function useGendersQuery(baseOptions?: Apollo.QueryHookOptions<GendersQuery, GendersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GendersQuery, GendersQueryVariables>(GendersDocument, options);
      }
export function useGendersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GendersQuery, GendersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GendersQuery, GendersQueryVariables>(GendersDocument, options);
        }
export type GendersQueryHookResult = ReturnType<typeof useGendersQuery>;
export type GendersLazyQueryHookResult = ReturnType<typeof useGendersLazyQuery>;
export type GendersQueryResult = Apollo.QueryResult<GendersQuery, GendersQueryVariables>;
export const BestPerformerThemesDocument = gql`
    query BestPerformerThemes($brandIds: [String!]!, $industryIds: [String!]!, $objectiveFamilyIds: [String!]!, $platforms: [Platform!]!, $after: DateTime!, $assetTypes: [AssetType!]) {
  bestPerformerThemes(
    filters: {brandIds: $brandIds, industryIds: $industryIds, objectiveFamilyIds: $objectiveFamilyIds, platforms: $platforms, after: $after, assetTypes: $assetTypes}
  ) {
    id
    name
  }
}
    `;

/**
 * __useBestPerformerThemesQuery__
 *
 * To run a query within a React component, call `useBestPerformerThemesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBestPerformerThemesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBestPerformerThemesQuery({
 *   variables: {
 *      brandIds: // value for 'brandIds'
 *      industryIds: // value for 'industryIds'
 *      objectiveFamilyIds: // value for 'objectiveFamilyIds'
 *      platforms: // value for 'platforms'
 *      after: // value for 'after'
 *      assetTypes: // value for 'assetTypes'
 *   },
 * });
 */
export function useBestPerformerThemesQuery(baseOptions: Apollo.QueryHookOptions<BestPerformerThemesQuery, BestPerformerThemesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BestPerformerThemesQuery, BestPerformerThemesQueryVariables>(BestPerformerThemesDocument, options);
      }
export function useBestPerformerThemesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BestPerformerThemesQuery, BestPerformerThemesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BestPerformerThemesQuery, BestPerformerThemesQueryVariables>(BestPerformerThemesDocument, options);
        }
export type BestPerformerThemesQueryHookResult = ReturnType<typeof useBestPerformerThemesQuery>;
export type BestPerformerThemesLazyQueryHookResult = ReturnType<typeof useBestPerformerThemesLazyQuery>;
export type BestPerformerThemesQueryResult = Apollo.QueryResult<BestPerformerThemesQuery, BestPerformerThemesQueryVariables>;
export const BestPerformersDocument = gql`
    query BestPerformers($limit: Int, $offset: Int, $brandIds: [String!]!, $industryIds: [String!]!, $objectiveFamilyIds: [String!]!, $platforms: [Platform!]!, $after: DateTime!, $assetTypes: [AssetType!], $themeIds: [String!]) {
  assets: bestPerformers(
    limit: $limit
    offset: $offset
    brandIds: $brandIds
    industryIds: $industryIds
    objectiveFamilyIds: $objectiveFamilyIds
    platforms: $platforms
    assetTypes: $assetTypes
    after: $after
    themeIds: $themeIds
    orderBy: ["roundedCpa", "totalSpend"]
    orderDesc: [false, true]
  ) {
    id
    totalResult
    asset {
      creativeScore
      fileUrl
      assetType
      analysisDate
    }
    cpa
    roundedCpa
    totalSpend
    nbAds
    nbCampaigns
  }
}
    `;

/**
 * __useBestPerformersQuery__
 *
 * To run a query within a React component, call `useBestPerformersQuery` and pass it any options that fit your needs.
 * When your component renders, `useBestPerformersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBestPerformersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      brandIds: // value for 'brandIds'
 *      industryIds: // value for 'industryIds'
 *      objectiveFamilyIds: // value for 'objectiveFamilyIds'
 *      platforms: // value for 'platforms'
 *      after: // value for 'after'
 *      assetTypes: // value for 'assetTypes'
 *      themeIds: // value for 'themeIds'
 *   },
 * });
 */
export function useBestPerformersQuery(baseOptions: Apollo.QueryHookOptions<BestPerformersQuery, BestPerformersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BestPerformersQuery, BestPerformersQueryVariables>(BestPerformersDocument, options);
      }
export function useBestPerformersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BestPerformersQuery, BestPerformersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BestPerformersQuery, BestPerformersQueryVariables>(BestPerformersDocument, options);
        }
export type BestPerformersQueryHookResult = ReturnType<typeof useBestPerformersQuery>;
export type BestPerformersLazyQueryHookResult = ReturnType<typeof useBestPerformersLazyQuery>;
export type BestPerformersQueryResult = Apollo.QueryResult<BestPerformersQuery, BestPerformersQueryVariables>;
export const BrandConfidencesDocument = gql`
    query BrandConfidences($brandIds: [String!]!, $industryIds: [String!]!, $objectiveFamilyIds: [String!]!, $platforms: [Platform!]!, $after: DateTime!, $assetTypes: [AssetType!], $featureCategories: [String!], $kpis: [String!], $themeIds: [String!]) {
  brandsConfidences(
    filters: {industryIds: $industryIds, brandIds: $brandIds, assetTypes: $assetTypes, objectiveFamilyIds: $objectiveFamilyIds, after: $after, platforms: $platforms, featureCategories: $featureCategories, kpis: $kpis, themeIds: $themeIds}
  ) {
    name
  }
}
    `;

/**
 * __useBrandConfidencesQuery__
 *
 * To run a query within a React component, call `useBrandConfidencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandConfidencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandConfidencesQuery({
 *   variables: {
 *      brandIds: // value for 'brandIds'
 *      industryIds: // value for 'industryIds'
 *      objectiveFamilyIds: // value for 'objectiveFamilyIds'
 *      platforms: // value for 'platforms'
 *      after: // value for 'after'
 *      assetTypes: // value for 'assetTypes'
 *      featureCategories: // value for 'featureCategories'
 *      kpis: // value for 'kpis'
 *      themeIds: // value for 'themeIds'
 *   },
 * });
 */
export function useBrandConfidencesQuery(baseOptions: Apollo.QueryHookOptions<BrandConfidencesQuery, BrandConfidencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrandConfidencesQuery, BrandConfidencesQueryVariables>(BrandConfidencesDocument, options);
      }
export function useBrandConfidencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrandConfidencesQuery, BrandConfidencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrandConfidencesQuery, BrandConfidencesQueryVariables>(BrandConfidencesDocument, options);
        }
export type BrandConfidencesQueryHookResult = ReturnType<typeof useBrandConfidencesQuery>;
export type BrandConfidencesLazyQueryHookResult = ReturnType<typeof useBrandConfidencesLazyQuery>;
export type BrandConfidencesQueryResult = Apollo.QueryResult<BrandConfidencesQuery, BrandConfidencesQueryVariables>;
export const BrandFeaturesDocument = gql`
    query BrandFeatures($brandIds: [String!]!, $industryIds: [String!]!, $objectiveFamilyIds: [String!]!, $platforms: [Platform!]!, $after: DateTime!, $assetTypes: [AssetType!], $kpis: [String!], $confidences: [String!], $themeIds: [String!]) {
  brandsFeatureCategories(
    filters: {industryIds: $industryIds, brandIds: $brandIds, assetTypes: $assetTypes, objectiveFamilyIds: $objectiveFamilyIds, after: $after, platforms: $platforms, kpis: $kpis, confidences: $confidences, themeIds: $themeIds}
  ) {
    categoryFrontName
  }
}
    `;

/**
 * __useBrandFeaturesQuery__
 *
 * To run a query within a React component, call `useBrandFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandFeaturesQuery({
 *   variables: {
 *      brandIds: // value for 'brandIds'
 *      industryIds: // value for 'industryIds'
 *      objectiveFamilyIds: // value for 'objectiveFamilyIds'
 *      platforms: // value for 'platforms'
 *      after: // value for 'after'
 *      assetTypes: // value for 'assetTypes'
 *      kpis: // value for 'kpis'
 *      confidences: // value for 'confidences'
 *      themeIds: // value for 'themeIds'
 *   },
 * });
 */
export function useBrandFeaturesQuery(baseOptions: Apollo.QueryHookOptions<BrandFeaturesQuery, BrandFeaturesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrandFeaturesQuery, BrandFeaturesQueryVariables>(BrandFeaturesDocument, options);
      }
export function useBrandFeaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrandFeaturesQuery, BrandFeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrandFeaturesQuery, BrandFeaturesQueryVariables>(BrandFeaturesDocument, options);
        }
export type BrandFeaturesQueryHookResult = ReturnType<typeof useBrandFeaturesQuery>;
export type BrandFeaturesLazyQueryHookResult = ReturnType<typeof useBrandFeaturesLazyQuery>;
export type BrandFeaturesQueryResult = Apollo.QueryResult<BrandFeaturesQuery, BrandFeaturesQueryVariables>;
export const BrandKpisDocument = gql`
    query BrandKpis($brandIds: [String!]!, $industryIds: [String!]!, $objectiveFamilyIds: [String!]!, $platforms: [Platform!]!, $after: DateTime!, $assetTypes: [AssetType!], $featureCategories: [String!], $confidences: [String!], $themeIds: [String!]) {
  brandKpis(
    filters: {industryIds: $industryIds, brandIds: $brandIds, assetTypes: $assetTypes, objectiveFamilyIds: $objectiveFamilyIds, after: $after, platforms: $platforms, featureCategories: $featureCategories, confidences: $confidences, themeIds: $themeIds}
  ) {
    kpi
    name
  }
}
    `;

/**
 * __useBrandKpisQuery__
 *
 * To run a query within a React component, call `useBrandKpisQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandKpisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandKpisQuery({
 *   variables: {
 *      brandIds: // value for 'brandIds'
 *      industryIds: // value for 'industryIds'
 *      objectiveFamilyIds: // value for 'objectiveFamilyIds'
 *      platforms: // value for 'platforms'
 *      after: // value for 'after'
 *      assetTypes: // value for 'assetTypes'
 *      featureCategories: // value for 'featureCategories'
 *      confidences: // value for 'confidences'
 *      themeIds: // value for 'themeIds'
 *   },
 * });
 */
export function useBrandKpisQuery(baseOptions: Apollo.QueryHookOptions<BrandKpisQuery, BrandKpisQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrandKpisQuery, BrandKpisQueryVariables>(BrandKpisDocument, options);
      }
export function useBrandKpisLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrandKpisQuery, BrandKpisQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrandKpisQuery, BrandKpisQueryVariables>(BrandKpisDocument, options);
        }
export type BrandKpisQueryHookResult = ReturnType<typeof useBrandKpisQuery>;
export type BrandKpisLazyQueryHookResult = ReturnType<typeof useBrandKpisLazyQuery>;
export type BrandKpisQueryResult = Apollo.QueryResult<BrandKpisQuery, BrandKpisQueryVariables>;
export const BrandThemesDocument = gql`
    query BrandThemes($brandIds: [String!]!, $industryIds: [String!]!, $objectiveFamilyIds: [String!]!, $platforms: [Platform!]!, $after: DateTime!, $assetTypes: [AssetType!], $featureCategories: [String!], $kpis: [String!], $confidences: [String!]) {
  ...BrandThemesQuery
}
    ${BrandThemesQueryFragmentDoc}`;

/**
 * __useBrandThemesQuery__
 *
 * To run a query within a React component, call `useBrandThemesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandThemesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandThemesQuery({
 *   variables: {
 *      brandIds: // value for 'brandIds'
 *      industryIds: // value for 'industryIds'
 *      objectiveFamilyIds: // value for 'objectiveFamilyIds'
 *      platforms: // value for 'platforms'
 *      after: // value for 'after'
 *      assetTypes: // value for 'assetTypes'
 *      featureCategories: // value for 'featureCategories'
 *      kpis: // value for 'kpis'
 *      confidences: // value for 'confidences'
 *   },
 * });
 */
export function useBrandThemesQuery(baseOptions: Apollo.QueryHookOptions<BrandThemesQuery, BrandThemesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrandThemesQuery, BrandThemesQueryVariables>(BrandThemesDocument, options);
      }
export function useBrandThemesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrandThemesQuery, BrandThemesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrandThemesQuery, BrandThemesQueryVariables>(BrandThemesDocument, options);
        }
export type BrandThemesQueryHookResult = ReturnType<typeof useBrandThemesQuery>;
export type BrandThemesLazyQueryHookResult = ReturnType<typeof useBrandThemesLazyQuery>;
export type BrandThemesQueryResult = Apollo.QueryResult<BrandThemesQuery, BrandThemesQueryVariables>;
export const HiddenGemThemesDocument = gql`
    query HiddenGemThemes($brandIds: [String!]!, $industryIds: [String!]!, $objectiveFamilyIds: [String!]!, $platforms: [Platform!]!, $after: DateTime!, $assetTypes: [AssetType!]) {
  hiddenGemThemes(
    filters: {brandIds: $brandIds, industryIds: $industryIds, objectiveFamilyIds: $objectiveFamilyIds, platforms: $platforms, after: $after, assetTypes: $assetTypes}
  ) {
    id
    name
  }
}
    `;

/**
 * __useHiddenGemThemesQuery__
 *
 * To run a query within a React component, call `useHiddenGemThemesQuery` and pass it any options that fit your needs.
 * When your component renders, `useHiddenGemThemesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHiddenGemThemesQuery({
 *   variables: {
 *      brandIds: // value for 'brandIds'
 *      industryIds: // value for 'industryIds'
 *      objectiveFamilyIds: // value for 'objectiveFamilyIds'
 *      platforms: // value for 'platforms'
 *      after: // value for 'after'
 *      assetTypes: // value for 'assetTypes'
 *   },
 * });
 */
export function useHiddenGemThemesQuery(baseOptions: Apollo.QueryHookOptions<HiddenGemThemesQuery, HiddenGemThemesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HiddenGemThemesQuery, HiddenGemThemesQueryVariables>(HiddenGemThemesDocument, options);
      }
export function useHiddenGemThemesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HiddenGemThemesQuery, HiddenGemThemesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HiddenGemThemesQuery, HiddenGemThemesQueryVariables>(HiddenGemThemesDocument, options);
        }
export type HiddenGemThemesQueryHookResult = ReturnType<typeof useHiddenGemThemesQuery>;
export type HiddenGemThemesLazyQueryHookResult = ReturnType<typeof useHiddenGemThemesLazyQuery>;
export type HiddenGemThemesQueryResult = Apollo.QueryResult<HiddenGemThemesQuery, HiddenGemThemesQueryVariables>;
export const HiddenGemsDocument = gql`
    query HiddenGems($offset: Int, $limit: Int, $brandIds: [String!]!, $industryIds: [String!]!, $objectiveFamilyIds: [String!]!, $platforms: [Platform!]!, $after: DateTime!, $assetTypes: [AssetType!], $themeIds: [String!]) {
  assets: hiddenGems(
    limit: $limit
    offset: $offset
    brandIds: $brandIds
    industryIds: $industryIds
    objectiveFamilyIds: $objectiveFamilyIds
    platforms: $platforms
    assetTypes: $assetTypes
    after: $after
    themeIds: $themeIds
  ) {
    id
    totalResult
    asset {
      creativeScore
      fileUrl
      assetType
      analysisDate
    }
    roundedCpa
    totalSpend
    nbAds
    nbCampaigns
  }
}
    `;

/**
 * __useHiddenGemsQuery__
 *
 * To run a query within a React component, call `useHiddenGemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHiddenGemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHiddenGemsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      brandIds: // value for 'brandIds'
 *      industryIds: // value for 'industryIds'
 *      objectiveFamilyIds: // value for 'objectiveFamilyIds'
 *      platforms: // value for 'platforms'
 *      after: // value for 'after'
 *      assetTypes: // value for 'assetTypes'
 *      themeIds: // value for 'themeIds'
 *   },
 * });
 */
export function useHiddenGemsQuery(baseOptions: Apollo.QueryHookOptions<HiddenGemsQuery, HiddenGemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HiddenGemsQuery, HiddenGemsQueryVariables>(HiddenGemsDocument, options);
      }
export function useHiddenGemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HiddenGemsQuery, HiddenGemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HiddenGemsQuery, HiddenGemsQueryVariables>(HiddenGemsDocument, options);
        }
export type HiddenGemsQueryHookResult = ReturnType<typeof useHiddenGemsQuery>;
export type HiddenGemsLazyQueryHookResult = ReturnType<typeof useHiddenGemsLazyQuery>;
export type HiddenGemsQueryResult = Apollo.QueryResult<HiddenGemsQuery, HiddenGemsQueryVariables>;
export const IndustryConfidencesDocument = gql`
    query IndustryConfidences($industryIds: [String!]!, $objectiveFamilyIds: [String!]!, $platforms: [Platform!]!, $after: DateTime!, $assetTypes: [AssetType!], $featureCategories: [String!], $kpis: [String!], $themeIds: [String!]) {
  industriesConfidences(
    filters: {industryIds: $industryIds, assetTypes: $assetTypes, objectiveFamilyIds: $objectiveFamilyIds, after: $after, platforms: $platforms, featureCategories: $featureCategories, kpis: $kpis, themeIds: $themeIds}
  ) {
    name
  }
}
    `;

/**
 * __useIndustryConfidencesQuery__
 *
 * To run a query within a React component, call `useIndustryConfidencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndustryConfidencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndustryConfidencesQuery({
 *   variables: {
 *      industryIds: // value for 'industryIds'
 *      objectiveFamilyIds: // value for 'objectiveFamilyIds'
 *      platforms: // value for 'platforms'
 *      after: // value for 'after'
 *      assetTypes: // value for 'assetTypes'
 *      featureCategories: // value for 'featureCategories'
 *      kpis: // value for 'kpis'
 *      themeIds: // value for 'themeIds'
 *   },
 * });
 */
export function useIndustryConfidencesQuery(baseOptions: Apollo.QueryHookOptions<IndustryConfidencesQuery, IndustryConfidencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IndustryConfidencesQuery, IndustryConfidencesQueryVariables>(IndustryConfidencesDocument, options);
      }
export function useIndustryConfidencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IndustryConfidencesQuery, IndustryConfidencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IndustryConfidencesQuery, IndustryConfidencesQueryVariables>(IndustryConfidencesDocument, options);
        }
export type IndustryConfidencesQueryHookResult = ReturnType<typeof useIndustryConfidencesQuery>;
export type IndustryConfidencesLazyQueryHookResult = ReturnType<typeof useIndustryConfidencesLazyQuery>;
export type IndustryConfidencesQueryResult = Apollo.QueryResult<IndustryConfidencesQuery, IndustryConfidencesQueryVariables>;
export const IndustryFeaturesDocument = gql`
    query IndustryFeatures($industryIds: [String!]!, $objectiveFamilyIds: [String!]!, $platforms: [Platform!]!, $after: DateTime!, $assetTypes: [AssetType!], $kpis: [String!], $confidences: [String!], $themeIds: [String!]) {
  industriesFeatureCategories(
    filters: {industryIds: $industryIds, assetTypes: $assetTypes, objectiveFamilyIds: $objectiveFamilyIds, after: $after, platforms: $platforms, kpis: $kpis, confidences: $confidences, themeIds: $themeIds}
  ) {
    categoryFrontName
  }
}
    `;

/**
 * __useIndustryFeaturesQuery__
 *
 * To run a query within a React component, call `useIndustryFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndustryFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndustryFeaturesQuery({
 *   variables: {
 *      industryIds: // value for 'industryIds'
 *      objectiveFamilyIds: // value for 'objectiveFamilyIds'
 *      platforms: // value for 'platforms'
 *      after: // value for 'after'
 *      assetTypes: // value for 'assetTypes'
 *      kpis: // value for 'kpis'
 *      confidences: // value for 'confidences'
 *      themeIds: // value for 'themeIds'
 *   },
 * });
 */
export function useIndustryFeaturesQuery(baseOptions: Apollo.QueryHookOptions<IndustryFeaturesQuery, IndustryFeaturesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IndustryFeaturesQuery, IndustryFeaturesQueryVariables>(IndustryFeaturesDocument, options);
      }
export function useIndustryFeaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IndustryFeaturesQuery, IndustryFeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IndustryFeaturesQuery, IndustryFeaturesQueryVariables>(IndustryFeaturesDocument, options);
        }
export type IndustryFeaturesQueryHookResult = ReturnType<typeof useIndustryFeaturesQuery>;
export type IndustryFeaturesLazyQueryHookResult = ReturnType<typeof useIndustryFeaturesLazyQuery>;
export type IndustryFeaturesQueryResult = Apollo.QueryResult<IndustryFeaturesQuery, IndustryFeaturesQueryVariables>;
export const IndustryKpisDocument = gql`
    query IndustryKpis($industryIds: [String!]!, $objectiveFamilyIds: [String!]!, $platforms: [Platform!]!, $after: DateTime!, $assetTypes: [AssetType!], $featureCategories: [String!], $confidences: [String!], $themeIds: [String!]) {
  industryKpis(
    filters: {industryIds: $industryIds, assetTypes: $assetTypes, objectiveFamilyIds: $objectiveFamilyIds, after: $after, platforms: $platforms, featureCategories: $featureCategories, confidences: $confidences, themeIds: $themeIds}
  ) {
    kpi
    name
  }
}
    `;

/**
 * __useIndustryKpisQuery__
 *
 * To run a query within a React component, call `useIndustryKpisQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndustryKpisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndustryKpisQuery({
 *   variables: {
 *      industryIds: // value for 'industryIds'
 *      objectiveFamilyIds: // value for 'objectiveFamilyIds'
 *      platforms: // value for 'platforms'
 *      after: // value for 'after'
 *      assetTypes: // value for 'assetTypes'
 *      featureCategories: // value for 'featureCategories'
 *      confidences: // value for 'confidences'
 *      themeIds: // value for 'themeIds'
 *   },
 * });
 */
export function useIndustryKpisQuery(baseOptions: Apollo.QueryHookOptions<IndustryKpisQuery, IndustryKpisQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IndustryKpisQuery, IndustryKpisQueryVariables>(IndustryKpisDocument, options);
      }
export function useIndustryKpisLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IndustryKpisQuery, IndustryKpisQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IndustryKpisQuery, IndustryKpisQueryVariables>(IndustryKpisDocument, options);
        }
export type IndustryKpisQueryHookResult = ReturnType<typeof useIndustryKpisQuery>;
export type IndustryKpisLazyQueryHookResult = ReturnType<typeof useIndustryKpisLazyQuery>;
export type IndustryKpisQueryResult = Apollo.QueryResult<IndustryKpisQuery, IndustryKpisQueryVariables>;
export const IndustryLearningsDocument = gql`
    query IndustryLearnings($offset: Int, $limit: Int, $industryIds: [String!]!, $objectiveFamilyIds: [String!]!, $platforms: [Platform!]!, $after: DateTime!, $assetTypes: [AssetType!], $featureCategories: [String!], $kpis: [String!], $confidences: [String!], $themeIds: [String!]) {
  learnings: industriesLearnings(
    limit: $limit
    offset: $offset
    filters: {industryIds: $industryIds, assetTypes: $assetTypes, objectiveFamilyIds: $objectiveFamilyIds, after: $after, platforms: $platforms, featureCategories: $featureCategories, kpis: $kpis, confidences: $confidences, themeIds: $themeIds}
  ) {
    ...LearningFragment
  }
}
    ${LearningFragmentFragmentDoc}`;

/**
 * __useIndustryLearningsQuery__
 *
 * To run a query within a React component, call `useIndustryLearningsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndustryLearningsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndustryLearningsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      industryIds: // value for 'industryIds'
 *      objectiveFamilyIds: // value for 'objectiveFamilyIds'
 *      platforms: // value for 'platforms'
 *      after: // value for 'after'
 *      assetTypes: // value for 'assetTypes'
 *      featureCategories: // value for 'featureCategories'
 *      kpis: // value for 'kpis'
 *      confidences: // value for 'confidences'
 *      themeIds: // value for 'themeIds'
 *   },
 * });
 */
export function useIndustryLearningsQuery(baseOptions: Apollo.QueryHookOptions<IndustryLearningsQuery, IndustryLearningsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IndustryLearningsQuery, IndustryLearningsQueryVariables>(IndustryLearningsDocument, options);
      }
export function useIndustryLearningsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IndustryLearningsQuery, IndustryLearningsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IndustryLearningsQuery, IndustryLearningsQueryVariables>(IndustryLearningsDocument, options);
        }
export type IndustryLearningsQueryHookResult = ReturnType<typeof useIndustryLearningsQuery>;
export type IndustryLearningsLazyQueryHookResult = ReturnType<typeof useIndustryLearningsLazyQuery>;
export type IndustryLearningsQueryResult = Apollo.QueryResult<IndustryLearningsQuery, IndustryLearningsQueryVariables>;
export const IndustryThemesDocument = gql`
    query IndustryThemes($industryIds: [String!]!, $objectiveFamilyIds: [String!]!, $platforms: [Platform!]!, $after: DateTime!, $assetTypes: [AssetType!], $featureCategories: [String!], $kpis: [String!], $confidences: [String!]) {
  ...IndustryThemesQuery
}
    ${IndustryThemesQueryFragmentDoc}`;

/**
 * __useIndustryThemesQuery__
 *
 * To run a query within a React component, call `useIndustryThemesQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndustryThemesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndustryThemesQuery({
 *   variables: {
 *      industryIds: // value for 'industryIds'
 *      objectiveFamilyIds: // value for 'objectiveFamilyIds'
 *      platforms: // value for 'platforms'
 *      after: // value for 'after'
 *      assetTypes: // value for 'assetTypes'
 *      featureCategories: // value for 'featureCategories'
 *      kpis: // value for 'kpis'
 *      confidences: // value for 'confidences'
 *   },
 * });
 */
export function useIndustryThemesQuery(baseOptions: Apollo.QueryHookOptions<IndustryThemesQuery, IndustryThemesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IndustryThemesQuery, IndustryThemesQueryVariables>(IndustryThemesDocument, options);
      }
export function useIndustryThemesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IndustryThemesQuery, IndustryThemesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IndustryThemesQuery, IndustryThemesQueryVariables>(IndustryThemesDocument, options);
        }
export type IndustryThemesQueryHookResult = ReturnType<typeof useIndustryThemesQuery>;
export type IndustryThemesLazyQueryHookResult = ReturnType<typeof useIndustryThemesLazyQuery>;
export type IndustryThemesQueryResult = Apollo.QueryResult<IndustryThemesQuery, IndustryThemesQueryVariables>;
export const InspirationsDocument = gql`
    query Inspirations($offset: Int, $limit: Int, $industryIds: [String!]!, $brandIds: [String!]!, $objectiveFamilyIds: [String!]!, $platforms: [Platform!]!, $after: DateTime!, $format: AssetType, $themeIds: [String!]) {
  assets(
    format: $format
    industryIds: $industryIds
    objectiveFamilyIds: $objectiveFamilyIds
    excludedBrandIds: $brandIds
    after: $after
    platforms: $platforms
    limit: $limit
    offset: $offset
    themeIds: $themeIds
  ) {
    id
    fileUrl
    creativeScore
    assetType
    spend
    analysisDate
    brand {
      name
    }
    platform
  }
}
    `;

/**
 * __useInspirationsQuery__
 *
 * To run a query within a React component, call `useInspirationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInspirationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInspirationsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      industryIds: // value for 'industryIds'
 *      brandIds: // value for 'brandIds'
 *      objectiveFamilyIds: // value for 'objectiveFamilyIds'
 *      platforms: // value for 'platforms'
 *      after: // value for 'after'
 *      format: // value for 'format'
 *      themeIds: // value for 'themeIds'
 *   },
 * });
 */
export function useInspirationsQuery(baseOptions: Apollo.QueryHookOptions<InspirationsQuery, InspirationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InspirationsQuery, InspirationsQueryVariables>(InspirationsDocument, options);
      }
export function useInspirationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InspirationsQuery, InspirationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InspirationsQuery, InspirationsQueryVariables>(InspirationsDocument, options);
        }
export type InspirationsQueryHookResult = ReturnType<typeof useInspirationsQuery>;
export type InspirationsLazyQueryHookResult = ReturnType<typeof useInspirationsLazyQuery>;
export type InspirationsQueryResult = Apollo.QueryResult<InspirationsQuery, InspirationsQueryVariables>;
export const LearnThemesDocument = gql`
    query LearnThemes($after: DateTime, $before: DateTime, $brandIds: [String!]!, $industryIds: [String!]!, $objectiveFamilyIds: [String!]!, $platforms: [Platform!]!, $assetTypes: [AssetType!], $format: AssetType, $kpis: [String!], $confidences: [String!], $ageRanges: [String!], $featureCategories: [String!]) {
  ...BrandThemesQuery
  ...IndustryThemesQuery
  ...AssetThemesQuery
}
    ${BrandThemesQueryFragmentDoc}
${IndustryThemesQueryFragmentDoc}
${AssetThemesQueryFragmentDoc}`;

/**
 * __useLearnThemesQuery__
 *
 * To run a query within a React component, call `useLearnThemesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLearnThemesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearnThemesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      brandIds: // value for 'brandIds'
 *      industryIds: // value for 'industryIds'
 *      objectiveFamilyIds: // value for 'objectiveFamilyIds'
 *      platforms: // value for 'platforms'
 *      assetTypes: // value for 'assetTypes'
 *      format: // value for 'format'
 *      kpis: // value for 'kpis'
 *      confidences: // value for 'confidences'
 *      ageRanges: // value for 'ageRanges'
 *      featureCategories: // value for 'featureCategories'
 *   },
 * });
 */
export function useLearnThemesQuery(baseOptions: Apollo.QueryHookOptions<LearnThemesQuery, LearnThemesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LearnThemesQuery, LearnThemesQueryVariables>(LearnThemesDocument, options);
      }
export function useLearnThemesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LearnThemesQuery, LearnThemesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LearnThemesQuery, LearnThemesQueryVariables>(LearnThemesDocument, options);
        }
export type LearnThemesQueryHookResult = ReturnType<typeof useLearnThemesQuery>;
export type LearnThemesLazyQueryHookResult = ReturnType<typeof useLearnThemesLazyQuery>;
export type LearnThemesQueryResult = Apollo.QueryResult<LearnThemesQuery, LearnThemesQueryVariables>;
export const LearningsDocument = gql`
    query Learnings($ids: [String!]!) {
  learnings(ids: $ids) {
    ...LearningFragment
  }
}
    ${LearningFragmentFragmentDoc}`;

/**
 * __useLearningsQuery__
 *
 * To run a query within a React component, call `useLearningsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLearningsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useLearningsQuery(baseOptions: Apollo.QueryHookOptions<LearningsQuery, LearningsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LearningsQuery, LearningsQueryVariables>(LearningsDocument, options);
      }
export function useLearningsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LearningsQuery, LearningsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LearningsQuery, LearningsQueryVariables>(LearningsDocument, options);
        }
export type LearningsQueryHookResult = ReturnType<typeof useLearningsQuery>;
export type LearningsLazyQueryHookResult = ReturnType<typeof useLearningsLazyQuery>;
export type LearningsQueryResult = Apollo.QueryResult<LearningsQuery, LearningsQueryVariables>;
export const MyLearningsDocument = gql`
    query MyLearnings($offset: Int, $limit: Int, $brandIds: [String!]!, $industryIds: [String!]!, $objectiveFamilyIds: [String!]!, $platforms: [Platform!]!, $after: DateTime!, $assetTypes: [AssetType!], $featureCategories: [String!], $kpis: [String!], $confidences: [String!], $themeIds: [String!]) {
  learnings: brandsLearnings(
    offset: $offset
    limit: $limit
    filters: {industryIds: $industryIds, brandIds: $brandIds, assetTypes: $assetTypes, objectiveFamilyIds: $objectiveFamilyIds, after: $after, platforms: $platforms, featureCategories: $featureCategories, kpis: $kpis, confidences: $confidences, themeIds: $themeIds}
  ) {
    ...LearningFragment
  }
}
    ${LearningFragmentFragmentDoc}`;

/**
 * __useMyLearningsQuery__
 *
 * To run a query within a React component, call `useMyLearningsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyLearningsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyLearningsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      brandIds: // value for 'brandIds'
 *      industryIds: // value for 'industryIds'
 *      objectiveFamilyIds: // value for 'objectiveFamilyIds'
 *      platforms: // value for 'platforms'
 *      after: // value for 'after'
 *      assetTypes: // value for 'assetTypes'
 *      featureCategories: // value for 'featureCategories'
 *      kpis: // value for 'kpis'
 *      confidences: // value for 'confidences'
 *      themeIds: // value for 'themeIds'
 *   },
 * });
 */
export function useMyLearningsQuery(baseOptions: Apollo.QueryHookOptions<MyLearningsQuery, MyLearningsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyLearningsQuery, MyLearningsQueryVariables>(MyLearningsDocument, options);
      }
export function useMyLearningsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyLearningsQuery, MyLearningsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyLearningsQuery, MyLearningsQueryVariables>(MyLearningsDocument, options);
        }
export type MyLearningsQueryHookResult = ReturnType<typeof useMyLearningsQuery>;
export type MyLearningsLazyQueryHookResult = ReturnType<typeof useMyLearningsLazyQuery>;
export type MyLearningsQueryResult = Apollo.QueryResult<MyLearningsQuery, MyLearningsQueryVariables>;
export const ObjectiveFamiliesDocument = gql`
    query ObjectiveFamilies {
  objectiveFamilies {
    id
    objectiveFamily
  }
}
    `;

/**
 * __useObjectiveFamiliesQuery__
 *
 * To run a query within a React component, call `useObjectiveFamiliesQuery` and pass it any options that fit your needs.
 * When your component renders, `useObjectiveFamiliesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useObjectiveFamiliesQuery({
 *   variables: {
 *   },
 * });
 */
export function useObjectiveFamiliesQuery(baseOptions?: Apollo.QueryHookOptions<ObjectiveFamiliesQuery, ObjectiveFamiliesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ObjectiveFamiliesQuery, ObjectiveFamiliesQueryVariables>(ObjectiveFamiliesDocument, options);
      }
export function useObjectiveFamiliesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ObjectiveFamiliesQuery, ObjectiveFamiliesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ObjectiveFamiliesQuery, ObjectiveFamiliesQueryVariables>(ObjectiveFamiliesDocument, options);
        }
export type ObjectiveFamiliesQueryHookResult = ReturnType<typeof useObjectiveFamiliesQuery>;
export type ObjectiveFamiliesLazyQueryHookResult = ReturnType<typeof useObjectiveFamiliesLazyQuery>;
export type ObjectiveFamiliesQueryResult = Apollo.QueryResult<ObjectiveFamiliesQuery, ObjectiveFamiliesQueryVariables>;
export const AnalysisCriteriasDocument = gql`
    query AnalysisCriterias($assetIds: [String!]!, $industryIds: [String!]!, $platforms: [Platform!]!, $objectiveFamilyIds: [String!]!) {
  learnings: creativeScoreLearnings(
    filters: {assetIds: $assetIds, industryIds: $industryIds, objectiveFamilyIds: $objectiveFamilyIds, platforms: $platforms}
  ) {
    liftId
    assetId
    industryId
    platform
    objectiveFamilyId
    learning {
      ...LearningFragment
    }
    weight
    isMatchingLift
    aggregationKey
  }
}
    ${LearningFragmentFragmentDoc}`;

/**
 * __useAnalysisCriteriasQuery__
 *
 * To run a query within a React component, call `useAnalysisCriteriasQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalysisCriteriasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalysisCriteriasQuery({
 *   variables: {
 *      assetIds: // value for 'assetIds'
 *      industryIds: // value for 'industryIds'
 *      platforms: // value for 'platforms'
 *      objectiveFamilyIds: // value for 'objectiveFamilyIds'
 *   },
 * });
 */
export function useAnalysisCriteriasQuery(baseOptions: Apollo.QueryHookOptions<AnalysisCriteriasQuery, AnalysisCriteriasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnalysisCriteriasQuery, AnalysisCriteriasQueryVariables>(AnalysisCriteriasDocument, options);
      }
export function useAnalysisCriteriasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnalysisCriteriasQuery, AnalysisCriteriasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnalysisCriteriasQuery, AnalysisCriteriasQueryVariables>(AnalysisCriteriasDocument, options);
        }
export type AnalysisCriteriasQueryHookResult = ReturnType<typeof useAnalysisCriteriasQuery>;
export type AnalysisCriteriasLazyQueryHookResult = ReturnType<typeof useAnalysisCriteriasLazyQuery>;
export type AnalysisCriteriasQueryResult = Apollo.QueryResult<AnalysisCriteriasQuery, AnalysisCriteriasQueryVariables>;
export const AnalysisGroupDetailDocument = gql`
    query AnalysisGroupDetail($ids: [String!]!) {
  analysisGroups(ids: $ids) {
    ...AnalysisGroupDetails
  }
}
    ${AnalysisGroupDetailsFragmentDoc}`;

/**
 * __useAnalysisGroupDetailQuery__
 *
 * To run a query within a React component, call `useAnalysisGroupDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalysisGroupDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalysisGroupDetailQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useAnalysisGroupDetailQuery(baseOptions: Apollo.QueryHookOptions<AnalysisGroupDetailQuery, AnalysisGroupDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnalysisGroupDetailQuery, AnalysisGroupDetailQueryVariables>(AnalysisGroupDetailDocument, options);
      }
export function useAnalysisGroupDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnalysisGroupDetailQuery, AnalysisGroupDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnalysisGroupDetailQuery, AnalysisGroupDetailQueryVariables>(AnalysisGroupDetailDocument, options);
        }
export type AnalysisGroupDetailQueryHookResult = ReturnType<typeof useAnalysisGroupDetailQuery>;
export type AnalysisGroupDetailLazyQueryHookResult = ReturnType<typeof useAnalysisGroupDetailLazyQuery>;
export type AnalysisGroupDetailQueryResult = Apollo.QueryResult<AnalysisGroupDetailQuery, AnalysisGroupDetailQueryVariables>;
export const AnalysisGroupsDocument = gql`
    query AnalysisGroups {
  analysisGroups {
    id
    name
    status
    numAssets
    createdAt
    user {
      id
      name
    }
    ...AnalysisGroupDetails
  }
}
    ${AnalysisGroupDetailsFragmentDoc}`;

/**
 * __useAnalysisGroupsQuery__
 *
 * To run a query within a React component, call `useAnalysisGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalysisGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalysisGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAnalysisGroupsQuery(baseOptions?: Apollo.QueryHookOptions<AnalysisGroupsQuery, AnalysisGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnalysisGroupsQuery, AnalysisGroupsQueryVariables>(AnalysisGroupsDocument, options);
      }
export function useAnalysisGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnalysisGroupsQuery, AnalysisGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnalysisGroupsQuery, AnalysisGroupsQueryVariables>(AnalysisGroupsDocument, options);
        }
export type AnalysisGroupsQueryHookResult = ReturnType<typeof useAnalysisGroupsQuery>;
export type AnalysisGroupsLazyQueryHookResult = ReturnType<typeof useAnalysisGroupsLazyQuery>;
export type AnalysisGroupsQueryResult = Apollo.QueryResult<AnalysisGroupsQuery, AnalysisGroupsQueryVariables>;
export const CreateAnalysisGroupDocument = gql`
    mutation CreateAnalysisGroup($name: String!) {
  createAnalysisGroup(name: $name) {
    id
    ...AnalysisGroupDetails
  }
}
    ${AnalysisGroupDetailsFragmentDoc}`;
export type CreateAnalysisGroupMutationFn = Apollo.MutationFunction<CreateAnalysisGroupMutation, CreateAnalysisGroupMutationVariables>;

/**
 * __useCreateAnalysisGroupMutation__
 *
 * To run a mutation, you first call `useCreateAnalysisGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAnalysisGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAnalysisGroupMutation, { data, loading, error }] = useCreateAnalysisGroupMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateAnalysisGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateAnalysisGroupMutation, CreateAnalysisGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAnalysisGroupMutation, CreateAnalysisGroupMutationVariables>(CreateAnalysisGroupDocument, options);
      }
export type CreateAnalysisGroupMutationHookResult = ReturnType<typeof useCreateAnalysisGroupMutation>;
export type CreateAnalysisGroupMutationResult = Apollo.MutationResult<CreateAnalysisGroupMutation>;
export type CreateAnalysisGroupMutationOptions = Apollo.BaseMutationOptions<CreateAnalysisGroupMutation, CreateAnalysisGroupMutationVariables>;
export const CreateAnalysisRequestDocument = gql`
    mutation CreateAnalysisRequest($createInputs: [AnalysisCreateInput!]!) {
  createAnalysisRequest(createInputs: $createInputs) {
    ... on AnalysisRequest {
      ...AnalysisRequestDetails
    }
    ... on AssetTypeError {
      message
    }
    ... on AssetSizeError {
      fileName
      maximumFileSize
    }
    ... on BrandsNotAllowedError {
      message
    }
    ... on ObjectiveFamiliesNotFoundError {
      message
    }
  }
}
    ${AnalysisRequestDetailsFragmentDoc}`;
export type CreateAnalysisRequestMutationFn = Apollo.MutationFunction<CreateAnalysisRequestMutation, CreateAnalysisRequestMutationVariables>;

/**
 * __useCreateAnalysisRequestMutation__
 *
 * To run a mutation, you first call `useCreateAnalysisRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAnalysisRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAnalysisRequestMutation, { data, loading, error }] = useCreateAnalysisRequestMutation({
 *   variables: {
 *      createInputs: // value for 'createInputs'
 *   },
 * });
 */
export function useCreateAnalysisRequestMutation(baseOptions?: Apollo.MutationHookOptions<CreateAnalysisRequestMutation, CreateAnalysisRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAnalysisRequestMutation, CreateAnalysisRequestMutationVariables>(CreateAnalysisRequestDocument, options);
      }
export type CreateAnalysisRequestMutationHookResult = ReturnType<typeof useCreateAnalysisRequestMutation>;
export type CreateAnalysisRequestMutationResult = Apollo.MutationResult<CreateAnalysisRequestMutation>;
export type CreateAnalysisRequestMutationOptions = Apollo.BaseMutationOptions<CreateAnalysisRequestMutation, CreateAnalysisRequestMutationVariables>;
export const DeleteAnalysisGroupDocument = gql`
    mutation deleteAnalysisGroup($analysisGroupId: String!) {
  deleteAnalysisGroup(analysisGroupId: $analysisGroupId) {
    ... on Success {
      message
    }
  }
}
    `;
export type DeleteAnalysisGroupMutationFn = Apollo.MutationFunction<DeleteAnalysisGroupMutation, DeleteAnalysisGroupMutationVariables>;

/**
 * __useDeleteAnalysisGroupMutation__
 *
 * To run a mutation, you first call `useDeleteAnalysisGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAnalysisGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAnalysisGroupMutation, { data, loading, error }] = useDeleteAnalysisGroupMutation({
 *   variables: {
 *      analysisGroupId: // value for 'analysisGroupId'
 *   },
 * });
 */
export function useDeleteAnalysisGroupMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAnalysisGroupMutation, DeleteAnalysisGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAnalysisGroupMutation, DeleteAnalysisGroupMutationVariables>(DeleteAnalysisGroupDocument, options);
      }
export type DeleteAnalysisGroupMutationHookResult = ReturnType<typeof useDeleteAnalysisGroupMutation>;
export type DeleteAnalysisGroupMutationResult = Apollo.MutationResult<DeleteAnalysisGroupMutation>;
export type DeleteAnalysisGroupMutationOptions = Apollo.BaseMutationOptions<DeleteAnalysisGroupMutation, DeleteAnalysisGroupMutationVariables>;
export const DeleteAnalysisRequestDocument = gql`
    mutation DeleteAnalysisRequest($analysisRequestId: String!) {
  deleteAnalysisRequest(analysisRequestId: $analysisRequestId) {
    ... on Success {
      sucessMessage: message
    }
    ... on NotFoundError {
      message
    }
  }
}
    `;
export type DeleteAnalysisRequestMutationFn = Apollo.MutationFunction<DeleteAnalysisRequestMutation, DeleteAnalysisRequestMutationVariables>;

/**
 * __useDeleteAnalysisRequestMutation__
 *
 * To run a mutation, you first call `useDeleteAnalysisRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAnalysisRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAnalysisRequestMutation, { data, loading, error }] = useDeleteAnalysisRequestMutation({
 *   variables: {
 *      analysisRequestId: // value for 'analysisRequestId'
 *   },
 * });
 */
export function useDeleteAnalysisRequestMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAnalysisRequestMutation, DeleteAnalysisRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAnalysisRequestMutation, DeleteAnalysisRequestMutationVariables>(DeleteAnalysisRequestDocument, options);
      }
export type DeleteAnalysisRequestMutationHookResult = ReturnType<typeof useDeleteAnalysisRequestMutation>;
export type DeleteAnalysisRequestMutationResult = Apollo.MutationResult<DeleteAnalysisRequestMutation>;
export type DeleteAnalysisRequestMutationOptions = Apollo.BaseMutationOptions<DeleteAnalysisRequestMutation, DeleteAnalysisRequestMutationVariables>;
export const RenameAnalysisDocument = gql`
    mutation RenameAnalysis($analysisId: String!, $name: String!) {
  updateAnalysisGroup(analysisGroupId: $analysisId, name: $name) {
    ... on AnalysisGroup {
      ...AnalysisGroupDetails
    }
    ... on NotFoundError {
      message
    }
  }
}
    ${AnalysisGroupDetailsFragmentDoc}`;
export type RenameAnalysisMutationFn = Apollo.MutationFunction<RenameAnalysisMutation, RenameAnalysisMutationVariables>;

/**
 * __useRenameAnalysisMutation__
 *
 * To run a mutation, you first call `useRenameAnalysisMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameAnalysisMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameAnalysisMutation, { data, loading, error }] = useRenameAnalysisMutation({
 *   variables: {
 *      analysisId: // value for 'analysisId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useRenameAnalysisMutation(baseOptions?: Apollo.MutationHookOptions<RenameAnalysisMutation, RenameAnalysisMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RenameAnalysisMutation, RenameAnalysisMutationVariables>(RenameAnalysisDocument, options);
      }
export type RenameAnalysisMutationHookResult = ReturnType<typeof useRenameAnalysisMutation>;
export type RenameAnalysisMutationResult = Apollo.MutationResult<RenameAnalysisMutation>;
export type RenameAnalysisMutationOptions = Apollo.BaseMutationOptions<RenameAnalysisMutation, RenameAnalysisMutationVariables>;
export const StartAnalysisGroupDocument = gql`
    mutation startAnalysisGroup($analysisGroupId: String!) {
  startAnalysisGroup(analysisGroupId: $analysisGroupId) {
    ... on AnalysisGroup {
      ...AnalysisGroupDetails
    }
  }
}
    ${AnalysisGroupDetailsFragmentDoc}`;
export type StartAnalysisGroupMutationFn = Apollo.MutationFunction<StartAnalysisGroupMutation, StartAnalysisGroupMutationVariables>;

/**
 * __useStartAnalysisGroupMutation__
 *
 * To run a mutation, you first call `useStartAnalysisGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartAnalysisGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startAnalysisGroupMutation, { data, loading, error }] = useStartAnalysisGroupMutation({
 *   variables: {
 *      analysisGroupId: // value for 'analysisGroupId'
 *   },
 * });
 */
export function useStartAnalysisGroupMutation(baseOptions?: Apollo.MutationHookOptions<StartAnalysisGroupMutation, StartAnalysisGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartAnalysisGroupMutation, StartAnalysisGroupMutationVariables>(StartAnalysisGroupDocument, options);
      }
export type StartAnalysisGroupMutationHookResult = ReturnType<typeof useStartAnalysisGroupMutation>;
export type StartAnalysisGroupMutationResult = Apollo.MutationResult<StartAnalysisGroupMutation>;
export type StartAnalysisGroupMutationOptions = Apollo.BaseMutationOptions<StartAnalysisGroupMutation, StartAnalysisGroupMutationVariables>;
export const UpdateAnalysisRequestDocument = gql`
    mutation UpdateAnalysisRequest($analysisRequestId: String!, $brand: String, $objectiveFamily: String, $platform: Platform, $industry: String) {
  updateAnalysisRequest(
    updateInput: {analysisRequestId: $analysisRequestId, brandId: $brand, objectiveFamilyId: $objectiveFamily, platform: $platform, industryId: $industry}
  ) {
    ... on AnalysisRequest {
      ...AnalysisRequestDetails
    }
    ... on NotFoundError {
      message
    }
    ... on AssetTypeError {
      message
    }
    ... on AssetSizeError {
      fileName
      maximumFileSize
    }
    ... on BrandsNotAllowedError {
      message
    }
    ... on ObjectiveFamiliesNotFoundError {
      message
    }
  }
}
    ${AnalysisRequestDetailsFragmentDoc}`;
export type UpdateAnalysisRequestMutationFn = Apollo.MutationFunction<UpdateAnalysisRequestMutation, UpdateAnalysisRequestMutationVariables>;

/**
 * __useUpdateAnalysisRequestMutation__
 *
 * To run a mutation, you first call `useUpdateAnalysisRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAnalysisRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAnalysisRequestMutation, { data, loading, error }] = useUpdateAnalysisRequestMutation({
 *   variables: {
 *      analysisRequestId: // value for 'analysisRequestId'
 *      brand: // value for 'brand'
 *      objectiveFamily: // value for 'objectiveFamily'
 *      platform: // value for 'platform'
 *      industry: // value for 'industry'
 *   },
 * });
 */
export function useUpdateAnalysisRequestMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAnalysisRequestMutation, UpdateAnalysisRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAnalysisRequestMutation, UpdateAnalysisRequestMutationVariables>(UpdateAnalysisRequestDocument, options);
      }
export type UpdateAnalysisRequestMutationHookResult = ReturnType<typeof useUpdateAnalysisRequestMutation>;
export type UpdateAnalysisRequestMutationResult = Apollo.MutationResult<UpdateAnalysisRequestMutation>;
export type UpdateAnalysisRequestMutationOptions = Apollo.BaseMutationOptions<UpdateAnalysisRequestMutation, UpdateAnalysisRequestMutationVariables>;
export const UserDocument = gql`
    query User {
  user {
    ...UserParts
  }
}
    ${UserPartsFragmentDoc}`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserQuery(baseOptions?: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;