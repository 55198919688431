import { LoadingScreen } from "@the-source/front-core/components";
import { ReactNode, useContext } from "react";
import Login from "../../pages/login";
import { AuthContext, AuthContextType } from "../context/authContext";

type Props = {
  children: ReactNode;
};

export default function AuthGuard({ children }: Props): JSX.Element {
  const { isLoading, hasUser } = useContext(AuthContext) as AuthContextType;

  return <>{hasUser() ? children : isLoading ? <LoadingScreen /> : <Login />}</>;
}
