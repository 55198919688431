export default function getRandomColor(string: string): string {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  return `hsl(${hash % 360}, 80%, 80%)`;
}
