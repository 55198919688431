//apollo resolves a cache ressource when a query arg is strictly equal to a ressource field
//for exemple querying a ressource with a single id
//but in the saas we often use an array of ids as a parameter so we need to match it manually using this read function

import { FieldPolicy } from "@apollo/client";

export default function idRessourceFromIdsArg(typename: string): FieldPolicy {
  return {
    read(existing, { args, toReference, canRead }) {
      if (!!args?.ids) {
        //since graphql is permissive (we can use a single id when the parameter expect an array of ids)
        //we have to transform it as an array if needed
        const ids = Array.isArray(args.ids) ? args.ids : [args.ids];
        const ressourcesRefs = ids.map((id) => toReference({ __typename: typename, id }));
        //if a single ressource among asked ressources is missing in cache we query backend
        //(could be improved if we need to query many ressources at once by their ids, not the case currently)
        if (ressourcesRefs.every((ressourcesRef) => canRead(ressourcesRef))) return ressourcesRefs;
        return undefined;
      } else return existing;
    },
  };
}
