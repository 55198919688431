import { Box, Card, Container, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Image, Page } from "@the-source/front-core/components";

import { LoginForm } from "../src/components/login";
import { contactEmail } from "../src/preset";

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

export default function Login(): JSX.Element {
  return (
    <>
      <Page title="Login">
        <RootStyle>
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <Image visibleByDefault disabledEffect src="/assets/illustration_login.png" alt="login" />
          </SectionStyle>

          <Container maxWidth="sm">
            <ContentStyle>
              <Stack direction="row" alignItems="center" sx={{ mb: 2 }}>
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="h4" gutterBottom>
                    Sign in to Creative Lift
                  </Typography>
                  <Typography sx={{ color: "text.secondary" }}>Enter your details below.</Typography>
                </Box>
              </Stack>

              <LoginForm />

              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                Don’t have an account?{" "}
                <a href={`mailto:${contactEmail.adress}?subject=${contactEmail.subject}`}>Contact us</a>
              </Typography>
            </ContentStyle>
          </Container>
        </RootStyle>
      </Page>
    </>
  );
}
